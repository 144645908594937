import { format } from 'date-fns';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { Sizes } from '../../../base/components/types';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { useTranslation } from '../../../base/hooks';
import { TextArea, TextAreaHeight } from '../../../base/ui/components/TextArea';
import Button from '../../../base/ui/components/buttons/Button';
import Container from '../../../base/ui/components/containers/Container';
import EmptyState, { EmptyStateType } from '../../../base/ui/components/emptyState/EmptyState';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon from '../../../base/ui/components/icons/Icon';
import InfoBox from '../../../base/ui/components/infoBox/InfoBox';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import Label from '../../../base/ui/components/labels/Label';
import EditAction from '../../../base/ui/components/util/EditAction';
import { showSnackbar } from '../../../base/ui/uiSlice';
import { getPrettyDate, isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { DetailedRequirement } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { complianceUpdateRequirementDescription, complianceUpdateRequirementEndDate, complianceUpdateRequirementName, complianceUpdateRequirementStartDate, deleteComplianceRequirementTemplate } from '../complianceSlice';

type Props = {
    className?: string;
    edit?: boolean;
    requirementData: DetailedRequirement;
};

const ComplianceRequirementInfo = (props: Props): React.ReactElement => {
    const { className, requirementData } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [name, setName] = useState(requirementData?.requirement_name?.text);
    const [description, setDescription] = useState(requirementData?.requirement_description?.text);
    const [startDate, setStartDate] = useState(requirementData?.requirement_start_date);
    const [endDate, setEndDate] = useState(requirementData?.requirement_end_date);
    const [edit, setEdit] = useState(props.edit);

    const done = () => {
        /* const data = {
            requirementId: requirementData.requirement_id,
            requirement_change_date: '',
            document_templates: [],
            requirement_description: description,
            requirement_name: name,
            requirement_tags: requirementData.requirement_tags,

        }
        dispatch(complianceEditRequirement(data)); */
        setEdit(false);
    }
    const updateName = () => {
        dispatch(complianceUpdateRequirementName({ requirementId: requirementData.requirement_id, name }));
    }
    const updateDescription = () => {
        dispatch(complianceUpdateRequirementDescription({ requirementId: requirementData.requirement_id, description }));
    }
    const updateStartDate = (date: string) => {
        dispatch(complianceUpdateRequirementStartDate({ requirementId: requirementData.requirement_id, date }));
    }
    const updateEndDate = (date: string) => {
        dispatch(complianceUpdateRequirementEndDate({ requirementId: requirementData.requirement_id, date }));
    }
    const getDisplayDate = () => {
        if (endDate) {
            return <span className='flex gap-x-1'><span>{getPrettyDate(requirementData.requirement_start_date)}</span>-<span>{getPrettyDate(requirementData.requirement_end_date)}</span></span>
        }
        return intl.formatMessage({ id: 'compliance.requirements.start_date_onwards' }, { date: getPrettyDate(requirementData?.requirement_start_date) })
    }
    const getDescription = () => {
        if (isUndefinedOrNullOrEmptyString(description)) {
            return <div className='cursor-pointer' onClick={() => setEdit(true)}><EmptyState type={EmptyStateType.Inline} title={<div className='flex gap-x-1 items-center'>No description yet<Icon className='text-brand' size={Sizes.Small} name='operation_edit' /></div>} headerClassName='justify-start' /></div>
        }
        return description;
    }
    const deleteRequirement = () => {
        dispatch(deleteComplianceRequirementTemplate(requirementData.requirement_id)).then(() => {
            dispatch(showSnackbar({
                hideAfter: 10000,
                content: <span className='flex gap-x-1'>
                    <FormattedMessage id='compliance.requirements.edit.delete_requirement.requirement_was_deleted' values={{ requirementName: requirementData.requirement_name.text, b: (chunks) => <strong>{chunks}</strong> }} />
                </span>,
            }));
        });
    }

    const info = <ContentSection
        content={
            <Container direction={edit ? 'col' : 'rows'} className='flex gap-x-4 rounded-md'>
                {edit && <div className='w-7/12 space-y-4'>
                    <Field
                        type='text'
                        label={{ text: useTranslation('compliance.requirements.edit.requirement_name') }}
                        value={name}
                        onChange={setName}
                        onBlur={updateName}
                    />
                    <TextArea
                        label={useTranslation('compliance.requirements.edit.requirement_description')}
                        height={TextAreaHeight.DEFAULT_AUTO_EXPAND}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => {
                            updateDescription();
                        }}
                    />
                    <div>
                        <Label text={useTranslation('compliance.requirements.edit.requirement_start_date')} />
                        <DatePicker3 maxDate={endDate ? new Date(endDate): null} showClose={false} date={startDate} handleChange={(d) => {
                            if (d) {
                                updateStartDate((format(d, DEFAULT_DATE_FORMAT2)));
                                setStartDate(format(d, DEFAULT_DATE_FORMAT2));
                            }
                        }} />
                    </div>
                    <div>
                        <Label text={useTranslation('compliance.requirements.edit.requirement_end_date')} />
                        <DatePicker3 minDate={new Date(startDate)} showClose={true} date={endDate} handleChange={(d) => {
                            if (d) {
                                updateEndDate((format(d, DEFAULT_DATE_FORMAT2)));
                                setEndDate(format(d, DEFAULT_DATE_FORMAT2));
                            } else {
                                updateEndDate(null);
                                setEndDate(null);
                            }
                        }} />
                    </div>
                    <InfoBox
                        header='Note'
                        message={<div className='space-y-4'>
                            <div className='space-x-1'>
                                <span className='font-bold'>{useTranslation('compliance.requirements.edit.requirement_start_date.note')}</span>
                                <span>{useTranslation('compliance.requirements.edit.requirement_start_date.note_text')}</span>
                            </div>
                            <div className='space-x-1'>
                                <span className='font-bold'>{useTranslation('compliance.requirements.edit.requirement_end_date.note')}</span>
                                <span>{useTranslation('compliance.requirements.edit.requirement_end_date.note_text')}</span>
                            </div>
                        </div>} />
                    <div className='items-center flex justify-between'><Button primary size={Sizes.Small} onPress={() => {
                        done();
                    }}><FormattedMessage id='globals.done' /></Button>
                        <DeleteConfirm
                            type='alert'
                            alertHeader={useTranslation('compliance.requirements.edit.delete_requirement.header')}
                            alertText={intl.formatMessage({ id: 'compliance.requirements.edit.delete_requirement.text' }, { b: (chunks) => <strong>{chunks}</strong> })}
                            alertButtonText={useTranslation('compliance.requirements.edit.delete_requirement.action_ok')}
                            deleteFunction={() => deleteRequirement()}
                            trigger={<span>
                                <Button size={Sizes.Small} stopPropagation={true} icon='operation_delete'>
                                    <FormattedMessage id='compliance.requirements.edit.delete_requirement' /></Button>
                            </span>} />
                    </div>
                </div>

                }
                {!edit && <div className='flex w-full gap-x-4 flex-row-reverse'>
                    <div className='w-5/12'>
                        <KeyValuePair keyItem={{ name: 'Requirement effective' }}
                            value={{ value: getDisplayDate() }}
                        />
                    </div>
                    <div className='w-7/12'>
                        <KeyValuePair keyItem={{ name: 'Description' }}
                            value={{ value: getDescription() }}
                        />
                    </div>
                    <EditAction beforeEditLabel={useTranslation('compliance.requirements.edit.edit_requirement_label')} onEditUpdated={setEdit} edit={edit} className='flex absolute' />
                </div>}
            </Container >} />
    return info
}
export default ComplianceRequirementInfo;
