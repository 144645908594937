import React from 'react';
import { Popup } from 'semantic-ui-react';
import Icon from '../../../base/components/basic/Icon';
import { Sizes } from '../../../base/components/types';
import Chip from '../../../base/ui/components/chips/Chip';
import { DetailedTag } from '../../../compliance_api/models';

type Props = {
    className?: string;
    tag: DetailedTag;
    isEditing?: boolean;
    onlyShowLast?: boolean;
    disabled?: boolean;
    index: number;
    size?: Sizes.Small | Sizes.Medium | Sizes.XSmall;
    removeTag?(index: number, tag: DetailedTag): void;
};

const ComplianceTag = (props: Props): React.ReactElement => {
    const { className, tag, onlyShowLast, isEditing, disabled } = props;
    const size = props.size || Sizes.Small;
    const removable = isEditing ? !disabled : false;
    const getLabel = () => {
        return tag.path ? 
        onlyShowLast ? tag.path[tag.path.length - 1] : tag.path && tag.path.map((p, i) => {
            return <div className='break-all whitespace-normal' key={'tag' + i}>{p} {i < (tag.path.length - 1) && '>'}</div>
        })
        : <div>-</div>
    }
    const trigger = <span>
        <Chip label={getLabel()} size={size} removable={removable} onRemove={() => disabled ? null : props.removeTag(props.index, tag)} />
        </span>;
    return (onlyShowLast) ? <Popup trigger={trigger}>
        <div className='capitalize font-bold'>{tag.category_name}</div>
        {tag.path?.map((p, i) => {
            return <div className='items-center flex' key='p'>{p} {i < (tag.path.length - 1) && <Icon name='arrow_right' />}</div>
        })}
    </Popup> : trigger;
}
export default ComplianceTag;
