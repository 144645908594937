import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Popup } from 'semantic-ui-react';
import { Supplier } from '../../../backend_api/models';
import Link from '../../../base/components/basic/Link';
import { Sizes } from '../../../base/components/types';
import { ComponentOrStringType } from '../../../base/types';
import Icon from '../../../base/ui/components/icons/Icon';
import { useAppDispatch } from '../../../store';

type Props = {
    className?: string;
    supplier: Supplier;
    trigger?: ComponentOrStringType;
};

const SupplierPopup = (props: Props): React.ReactElement => {
    const { className, trigger, supplier } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <Popup
        on='hover'
        trigger={<div>{trigger || supplier.name}</div>}
        hoverable
    >
        <div className='gap-y-2 flex flex-col'>
            <div>
                <div className='flex gap-x-1 text-secondary'><Icon className='text-secondary' name='user_supplier' /><span>Supplier</span></div>
                <span className=''>{supplier.name}</span>
            </div>
            <Link to={'/suppliers/' + supplier.id}><div className='flex items-center text-brand text-sm space-x-1'><span>View supplier</span><Icon className='text-brand' size={Sizes.Small} name='microinteraction_external-link' /></div></Link>
        </div>
    </Popup>
}
export default SupplierPopup;
