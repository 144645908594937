import React, { useEffect, useState } from 'react';
import { usePress } from 'react-aria';
import { DialogTrigger, OverlayArrow, Popover } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import { Sizes } from '../../../components/types';
import { Breakpoints } from '../../../config';
import { ComponentOrStringType } from '../../../types';
import { preventDefaultAndStopPropagation, twMerge, useMaxWidthBreak } from '../../../utils';
import Button from '../buttons/Button';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';
import { MicroInteractionContextMenu } from '../MicroInteractions';


type Props = {
    className?: string;
    overflowItems?: ContextMenuOverflowItemType[];
    trigger?: ComponentOrStringType;
    actions?: ContextMenuActionItemType[];
};

export type ContextMenuOverflowItemType = {
    icon?: Icons;
    label?: string;
    isSplitter?: boolean;
    className?: string;
    onPress?(): void;
    onClose?(): void;
}
export type ContextMenuActionItemType = {
    icon?: Icons;
    label: string;
    labelRight?: boolean;
    labelLeft?: boolean;
    className?: string;
    onPress?(): void;
}

export const ContextMenu = (props: Props): React.ReactElement => {
    const { className, trigger, actions } = props;
    const isMobile = useMaxWidthBreak(Breakpoints.SM);
    const actionsCount = actions?.length;
    const setOverflowItems = () => {
        let items: ContextMenuOverflowItemType[] = [];
        if (isMobile) {
            actions && actions.map((action) => {
                items.push({
                    label: action.label,
                    className: action.className,
                    icon: action.icon,
                    onPress: action.onPress,
                    onClose: null,
                })
            });
            if(!props.overflowItems) {
                return items;
            }
            return [...items, ...props.overflowItems];
        }
        return props.overflowItems;
    }
    const [overflowItems, updateOverflowItems] = useState(setOverflowItems());
    const [isOpen, setIsOpen] = useState(false);

    const border = 'border-default border';
    const bg = 'bg-surface-interactive';
    const text = 'text-on-surface-interactive text-sm leading-rem-sm';
    const overflowItemsStyle = twMerge('text-sm p-4 shadow-lg rounded bg-default w-[200px]');
    const actionButtonStyle = ('rounded-md text-sm px-2 py-0 min-h-rem-lg');
    // const actionButtonStyle = ('outline-none  px-2 py-2 leading-rem-lg rounded text-sm');

    const hasActions = isMobile ? false : actions && actions.length > 0;

    const actionItems = actions && actions.map((action, i) => {
        // const { pressProps, isPressed } = usePress({
        //     onPress: (e) => action.onPress && action.onPress()
        // });
        return <Button
            context
            className={twMerge(action.className, actionButtonStyle, text, actionsCount > 1 &&  (i < actionsCount - 1 )&& 'rounded-r-none', i > 0 && actionsCount > 1 &&  'rounded-l-none')}
            icon={action.icon}
            label={action.label}
            labelLeft={action.labelLeft}
            labelRight={action.labelRight}
            onPress={() => action.onPress && action.onPress()}
            size={Sizes.Small}
        >
            {(!action.labelLeft || !action.labelRight) && <span className='visually-hidden'><FormattedMessage id={action?.label || ''} /></span>}
        </Button>
    })
    const triggerItem = overflowItems?.length > 0 && <DialogTrigger onOpenChange={setIsOpen} isOpen={isOpen}>
        {trigger ?
            <div className='h-fit'>{trigger}</div>
            :
            <Button context className={twMerge(actionButtonStyle, hasActions && 'rounded-l-none')}>
                <MicroInteractionContextMenu />
                <span className='visually-hidden'><FormattedMessage id='context_menu.trigger.hidden_label' /></span>
            </Button>}
        <Popover className={twMerge(overflowItemsStyle, border, '')}
        >
            <OverlayArrow className={'group'}>
                <svg
                    viewBox="0 0 12 12"
                    stroke="#E5E7EB" stroke-width="1"
                    className="translate(0,5) block fill-white group-placement-bottom:rotate-180 w-4 h-4"
                >
                    <path d="M0 0 L6 6 L12 0" />
                </svg>
            </OverlayArrow>
            <div className={twMerge('flex flex-col gap-y-4')}>
                {overflowItems.map((overflowItem) => {
                    overflowItem.onClose = () => setIsOpen(false);
                    return <OverflowItem overflowItem={overflowItem} />
                })}
            </div>
        </Popover>
    </DialogTrigger>
    useEffect(() => {
        updateOverflowItems(setOverflowItems())
    }, [isMobile, actions, props.overflowItems])
    return <div className={twMerge(className, 'flex gap-1')}>{[!isMobile && actionItems, triggerItem]}</div>;
}

const OverflowItem = (props: { overflowItem: ContextMenuOverflowItemType }) => {
    const overflowItem = props.overflowItem;
    const overflowItemStyle = twMerge('text-primary cursor-pointer flex gap-1 items-center');

    const { pressProps, isPressed } = usePress({
        onPress: (e) => {
            overflowItem.onPress && overflowItem.onPress();
            overflowItem.onPress && overflowItem.onClose && overflowItem.onClose();
        }
    });
    return <div {...pressProps} className={twMerge(overflowItemStyle)} onClick={(e) => preventDefaultAndStopPropagation(e)}>
        {overflowItem && <>
            {overflowItem.isSplitter && <div className='border-t border-default w-full'></div>}
            {overflowItem.icon && <Icon size={Sizes.Small} className='text-primary' name={overflowItem.icon} />}
            <FormattedMessage id={overflowItem.label && overflowItem.label || ''} />
        </>}
    </div>

}
export default ContextMenu;
