import React, { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Radio } from '../../../base/components/basic/CheckboxRadio';
import { useTranslation } from '../../../base/hooks';
import { TextArea, TextAreaHeight } from '../../../base/ui/components/TextArea';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import Container from '../../../base/ui/components/containers/Container';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon from '../../../base/ui/components/icons/Icon';
import InvalidFieldMessage from '../../../base/ui/components/labels/InvalidFieldMessage';
import Label from '../../../base/ui/components/labels/Label';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { DocumentTemplateScopeProductTypeEnum, DocumentTemplateScopeSupplierOrProductionUnitTypeEnum, ExpirationFromFileTypeEnum, FrequencyFrequencyTypeEnum, FrequencyTypeEnum, NoExpirationTypeEnum, PerOrderTypeEnum, ResponsibleRequest, SharedDocumentTemplateResponsible } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { NewDocumentTemplateExtended, complianceCreateDocumentSpecification } from '../complianceSlice';
import { default as ComplianceRequirementDocumentApprovalResponsible, default as ComplianceRequirementDocumentResponsible } from './ComplianceRequirementDocumentApprovalResponsible';

type Props = {
    close(): void;
    requirementId: string;
    open: boolean;
};
export const convertSharedResponsibleToRequesFormat = (responsible: SharedDocumentTemplateResponsible): ResponsibleRequest => {
    const res: ResponsibleRequest = {
        user_ids: responsible.users?.map((u) => u.id),
        group_ids: responsible.groups?.map((g: any) => g.group_id || g.id),
        supplier: responsible.supplier,
        product_responsibles: responsible.product_responsibles,
    }
    return res;
}

const ComplianceRequirementAddDocumentSpecifictionModal = (props: Props): React.ReactElement => {
    const { requirementId } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [open, setIsOpen] = useState(props.open);
    const frequencyValues = [12, 24, 60];
    const frequencyDefaultValue = 24
    const [name, setName] = useState(null);
    const [date, setDate] = useState(null);
    const [description, setDescription] = useState(null);
    const [expiryRule, setExpiryRule] = useState(null);
    const [frequency, setFrequency] = useState(frequencyValues[1]);
    const [documentScope, setDocumentScope] = useState(null);
    const [documentApproval, setDocumentApproval] = useState('no_approval');
    const [documentApprovalResponsibles, setDocumentApprovalResponsibles] = useState<ResponsibleRequest>(null);
    const [documentUploadResponsibles, setDocumentUploadResponsibles] = useState<ResponsibleRequest>(null);
    const errorState = { name: null, expiryRule: null, documentScope: null, approvalResponsible: null, uploadResponsible: null };
    const [errorMessage, setErrorMessage] = useState(errorState);
    const iconClass = 'text-primary';

    useEffect(() => {
        setIsOpen(props.open);
    }, [props.open])

    useEffect(() => {
        expiryRule && checkValidation('expiry')
    }, [expiryRule])

    useEffect(() => {
        documentScope && checkValidation('documentScope')
    }, [documentScope])

    useEffect(() => {
        documentApprovalResponsibles && checkValidation('approvalResponsible')
    }, [documentApprovalResponsibles])

    useEffect(() => {
        documentUploadResponsibles && checkValidation('uploadResponsible')
    }, [documentUploadResponsibles])

    const close = () => {
        setName(null);
        setDescription(null);
        setDate(null);
        setExpiryRule(null);
        setDocumentApproval('no_approval');
        setDocumentScope(null);
        setDocumentApprovalResponsibles(null);
        setDocumentUploadResponsibles(null);
        setErrorMessage(errorState);
        props.close?.();

    }
    const checkValidation = (type?: string) => {
        const t = !type ? 'all' : type;
        const nameValid = !isUndefinedOrNullOrEmptyString(name);
        const nameError = translate('compliance.requirements.create.document_specification.name.error');
        const expiryValid = !isUndefinedOrNullOrEmptyString(expiryRule);
        const expiryError = translate('compliance.requirements.create.requirement_expiry.error');
        const documentScopeValid = !isUndefinedOrNullOrEmptyString(documentScope);
        const documentScopeError = translate('compliance.requirements.create.requirement_document_scope.error');
        const approvalResponsibleValid = !isUndefinedOrNullOrEmptyString(documentApprovalResponsibles) && (documentApprovalResponsibles && Object.values(documentApprovalResponsibles).includes(true) || (documentApprovalResponsibles?.group_ids?.length > 0 || documentApprovalResponsibles?.user_ids?.length > 0));
        const approvalResponsibleError = translate('compliance.requirements.create.requirement_approval_responsible.error');
        const uploadResponsibleValid = !isUndefinedOrNullOrEmptyString(documentUploadResponsibles) && (documentUploadResponsibles && Object.values(documentUploadResponsibles).includes(true) || (documentUploadResponsibles?.group_ids?.length > 0 || documentUploadResponsibles?.user_ids?.length > 0));
        const uploadResponsibleError = translate('compliance.requirements.create.requirement_upload_responsible.error');

        if (t === 'name') {
            setErrorMessage({
                ...errorMessage,
                name: nameValid ? null : nameError,
            })
            return nameValid;
        }
        if (t === 'expiry') {
            setErrorMessage({
                ...errorMessage,
                expiryRule: expiryValid ? null : expiryError,
            })
            return expiryValid;
        }
        if (t === 'documentScope') {
            setErrorMessage({
                ...errorMessage,
                documentScope: documentScopeValid ? null : documentScopeError,
            })
            return documentScopeValid;
        }
        if (t === 'approvalResponsible') {
            setErrorMessage({
                ...errorMessage,
                approvalResponsible: approvalResponsibleValid ? null : approvalResponsibleError,
            })
            return approvalResponsibleValid;
        }
        if (t === 'uploadResponsible') {
            setErrorMessage({
                ...errorMessage,
                uploadResponsible: uploadResponsibleValid ? null : uploadResponsibleError,
            })
            return uploadResponsibleValid;
        }
        setErrorMessage({
            ...errorMessage,
            name: nameValid ? null : nameError,
            expiryRule: expiryValid ? null : expiryError,
            documentScope: documentScopeValid ? null : documentScopeError,
            approvalResponsible: approvalResponsibleValid ? null : approvalResponsibleError,
            uploadResponsible: uploadResponsibleValid ? null : uploadResponsibleError,
        })
        return expiryValid && nameValid && documentScopeValid && approvalResponsibleValid && uploadResponsibleValid;
    }

    const save = () => {
        if (checkValidation()) {
            const newDocumentSpecification: NewDocumentTemplateExtended = {
                requirementId,
                name,
                description,
                expiration_rule: { type: expiryRule, frequency_value: frequency, frequency_type: FrequencyFrequencyTypeEnum.Month },
                requires_approval: false,
                approve_responsible: {
                    group_ids: documentApprovalResponsibles?.group_ids || [],
                    user_ids: documentApprovalResponsibles?.user_ids || [],
                    product_responsibles: documentApprovalResponsibles?.product_responsibles || false,
                    supplier: documentApprovalResponsibles?.supplier || false,
                },
                upload_responsible: {
                    group_ids: documentUploadResponsibles?.group_ids || [],
                    user_ids: documentUploadResponsibles?.user_ids || [],
                    product_responsibles: documentUploadResponsibles?.product_responsibles || false,
                    supplier: documentUploadResponsibles?.supplier || false,
                },
                scope: { type: documentScope }
            }
            dispatch(complianceCreateDocumentSpecification(newDocumentSpecification));
            setErrorMessage(errorState);
            setIsOpen(false);
            close();
        }
    }

    const translate = (key) => {
        return intl.formatMessage({ id: key });
    }

    const expiryRules = <div className='space-y-2'>
        <div className='font-bold'><FormattedMessage id='compliance.requirements.document_specification.expiration_rule' /></div>
        {errorMessage.expiryRule && <InvalidFieldMessage message={errorMessage.expiryRule} />}
        <div className='flex gap-x-1 cursor-pointer' onClick={() => {
            setExpiryRule(NoExpirationTypeEnum.NoExpiration);
        }} ><Radio name='expiry_rule' checked={expiryRule === NoExpirationTypeEnum.NoExpiration} /><Icon className={iconClass} name='expiry_never' /> <FormattedMessage id='compliance.requirements.document_specification.expiration_rule.no_expiration' /></div>
        <div className='flex gap-x-1 cursor-pointer' onClick={() => {
            setExpiryRule(PerOrderTypeEnum.PerOrder);
        }}><Radio name='expiry_rule' checked={expiryRule === PerOrderTypeEnum.PerOrder} /><Icon className={iconClass} name='expiry_by-order' /><FormattedMessage id='compliance.requirements.document_specification.expiration_rule.new_document_on_every_order' /></div>
        <div className='flex gap-x-1 cursor-pointer' onClick={() => setExpiryRule(ExpirationFromFileTypeEnum.ExpirationFromFile)} ><Radio name='expiry_rule' checked={expiryRule === ExpirationFromFileTypeEnum.ExpirationFromFile} /><Icon className={iconClass} name='expiry_time-based' /><FormattedMessage id='compliance.requirements.document_specification.expiration_rule.determined_by_dates' /></div>
        <div className='flex gap-x-1 cursor-pointer' onClick={() => setExpiryRule(FrequencyTypeEnum.Frequency)}><Radio name='expiry_rule' checked={expiryRule === FrequencyTypeEnum.Frequency} /><Icon className={iconClass} name='expiry_renewal' /><FormattedMessage id='compliance.requirements.document_specification.expiration_rule.renewal_after_a_period_of_time' /></div>
        {expiryRule === FrequencyTypeEnum.Frequency && <div className='pt-4'>
            <Label text={translate('compliance.requirements.document_specification.expiration_rule.renewal_frequency')} />
            <Dropdown search selection fluid defaultValue={frequencyDefaultValue} value={frequency} onChange={(e, item) => setFrequency(item.value as number)}
                options={[
                    { text: useTranslation('compliance.requirements.document_specification.edit_frequency.frequency_every', { months: 12 }), id: 'm-12', value: 12 },
                    { text: useTranslation('compliance.requirements.document_specification.edit_frequency.frequency_every', { months: 24 }), id: 'm-24', value: 24 },
                    { text: useTranslation('compliance.requirements.document_specification.edit_frequency.frequency_every_years', { years: 5 }), id: 'y-5', value: 60 },
                ]} />
        </div>}
    </div>
    const documentScopes = <div className='space-y-2'>
        <div className='font-bold'><FormattedMessage id='compliance.requirements.document_specification.document_scope' /></div>
        {errorMessage.documentScope && <InvalidFieldMessage message={errorMessage.documentScope} />}
        <div className='flex gap-x-1 cursor-pointer' onClick={() => setDocumentScope(DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope)} ><Radio checked={documentScope === DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope} name='doc_scope' /><Icon className={iconClass} name='object_product' /><FormattedMessage id='compliance.requirements.document_specification.document_scope.product' /></div>
        <div className='flex gap-x-1 cursor-pointer' onClick={() => setDocumentScope(DocumentTemplateScopeSupplierOrProductionUnitTypeEnum.DocumentTemplateSupplierOrProductionUnitScope)} ><Radio checked={documentScope === DocumentTemplateScopeSupplierOrProductionUnitTypeEnum.DocumentTemplateSupplierOrProductionUnitScope} name='doc_scope' /><Icon className={iconClass} name='user_supplier' /><FormattedMessage id='compliance.requirements.document_template_scope.supplier_scope.text' /></div>
    </div>
    const documentApprovals = <div className='space-y-2'>
        <div className='font-bold'><FormattedMessage id='compliance.requirements.document_specification.document_approval' /></div>
        <div className='flex gap-x-1  cursor-pointer' onClick={() => setDocumentApproval('no_approval')} ><Radio checked={documentApproval === 'no_approval'} name='doc_approval' /><FormattedMessage id='compliance.requirements.document_specification.document_approval.no_approval_required' /></div>
        <div className='flex gap-x-1  cursor-pointer' onClick={() => setDocumentApproval('requires_approval')}><Radio checked={documentApproval === 'requires_approval'} name='doc_approval' /><FormattedMessage id='compliance.requirements.document_specification.document_approval.document_requires_approval' /></div>
    </div>

    const responsibleForApproval =
        <div className='space-y-2'>
            <div className='font-bold'><FormattedMessage id='compliance.requirements.document_specification.responsible_for_approval' /></div>
            {errorMessage.approvalResponsible && <InvalidFieldMessage message={errorMessage.approvalResponsible} />}
            <ComplianceRequirementDocumentApprovalResponsible requirementId=''
                onUpdated={(res) => {
                    setDocumentApprovalResponsibles(convertSharedResponsibleToRequesFormat(res));
                }} />
        </div>
    const responsibleForUpload =
        <div className='space-y-2'>
            <div className='font-bold'><FormattedMessage id='compliance.requirements.document_specification.responsible_for_adding_files' /></div>
            {errorMessage.uploadResponsible && <InvalidFieldMessage message={errorMessage.uploadResponsible} />}
            <ComplianceRequirementDocumentResponsible requirementId=''
                onUpdated={(res) => setDocumentUploadResponsibles(convertSharedResponsibleToRequesFormat(res))} />
        </div>

    const createModal = <Modal
        onOpenChange={(c) => {
            !c && close();
            setIsOpen(c)
        }}
        onClose={() => {
            setIsOpen(false);
            close();
        }}
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'compliance.requirements.create.create_document_specification_action' }), stayOpen: true, event: save, /* disabled: isUndefinedOrNullOrEmptyString(productName) || isUndefinedOrNullOrEmptyString(productNumber) */ },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: close },
        ]}
        isOpen={open}
        width={500}
        header={translate('compliance.requirements.add_document_specification_header')}
    >
        <Container className=''>

            <Field
                label={{ text: intl.formatMessage({ id: 'compliance.requirements.document_specification.document_name' }) }}
                value={name}
                onChange={setName}
                onBlur={() => checkValidation('name')}
                errorMessage={errorMessage.name}
            />
            <TextArea

                label={translate('compliance.requirements.document_specification.document_description')}
                height={TextAreaHeight.DEFAULT_AUTO_EXPAND}
                value={description}
                onChange={setDescription}
            />
            <div className='space-y-4'>
                {documentScopes}
                {expiryRules}
                {responsibleForUpload}
                {/* documentApprovals */}
                {/* documentApproval === 'requires_approval' && responsibleForApproval */}
                {responsibleForApproval}

            </div>

        </Container>
    </Modal >

    return createModal;
}
export default ComplianceRequirementAddDocumentSpecifictionModal;
