import { format } from 'date-fns';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Modal, Popup, TextArea } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { CorrectiveAction, ReportCheckpointHeader, ReportDefect, TextWithTranslation } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import Icon from '../../../base/components/basic/Icon';
import { translateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { getFeaturesSelector } from '../../../base/selectors';
import { AppState, byId } from '../../../base/types';
import { addArrayItemOrRemoveIfExists, getLocaleLanguageString, getStringsSeparatedBy, isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAuditCheckpointsSelector, getReportCheckpointWithRemarksIdsSelector } from '../../audit/selectors/auditSelectors';
import { SeverityLabel } from '../../globals/components/Severity';
import { getReportCheckpointsSelector } from '../../inspections/selectors/reportSelectors';
import { getReportDefectsSelector } from '../../inspections/slices/inspectionReportSlice';
import { checkHasFeature } from '../../profile/containers/HasFeature';
import UsersSelector from '../../users/components/UsersSelector';
import { createCorrectiveActionOnAudit, createCorrectiveActionOnInspection } from '../correctiveActionsSlice';
import { auditActionsSelector } from '../selectors/correctiveActionSelectors';

export enum CorrectiveActionsFromDefectOrCheckpointsType { Inspection = 'inspection', Audit = 'audit' }
export enum CorrectiveActionsType { Checkpoint = 'checkpoint', Defect = 'defect' }


type CorrectiveActionsFromCheckpointsWizardProps = {
    className?: string;
    id: string;
    selectedIds?: string[]; // Defect or chp ids
    createOnType?: CorrectiveActionsFromDefectOrCheckpointsType;
    itemType: 'defect' | 'checkpoint';
    trigger?: ReactElement;
};

const CorrectiveActionsFromCheckpointsOrDefectsWizard = (props: CorrectiveActionsFromCheckpointsWizardProps): React.ReactElement => {
    const { className, id = '', itemType, createOnType, selectedIds, trigger } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    enum Step { Select = 0, Apply = 1 }
    const features = useSelector(getFeaturesSelector);
    const isInspection = createOnType === CorrectiveActionsFromDefectOrCheckpointsType.Inspection;
    const isCheckpoint = itemType === CorrectiveActionsType.Checkpoint;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItemsCnt, setSelectedItemsCnt] = useState(0)
    const [step, setStep] = useState<Step>(selectedIds ? 1 : 0)
    const [findingEditable, toggleFindingEditable] = useState(undefined);
    const [descriptionEditable, toggleDescriptionEditable] = useState(undefined);
    const [responsibleId, setResponsibleValue] = useState<string>(undefined);
    const [approverId, setApproverValue] = useState<string>(undefined);
    const [dueDate, setDueDateValue] = useState<string>(undefined);
    const [requireEvidenceFromApp, setRequireEvidenceFromAppValue] = useState<boolean>(true);
    const [openHeaders, setOpenHeaders] = useState([]);
    const [CAs, updateCAs] = useState<CAItems>({});
    const auditCheckpoints = useSelector(getAuditCheckpointsSelector) || [];
    const inspectionCheckpoints = useSelector(getReportCheckpointsSelector) || [];

    const auditCorrectiveActions = useSelector((state: AppState) => auditActionsSelector(state, id));
    const checkpointsWithCAP = buildMapOfCheckpointWithCAP(auditCorrectiveActions);

    const cpsWithRemarks = useSelector(getReportCheckpointWithRemarksIdsSelector);
    const defects = useSelector(getReportDefectsSelector);
    const cpsWithRemarksIds = cpsWithRemarks.map((id) => id.id);
    const canCreateCorrectiveAction = (checkHasFeature(features, 'u_corrective_actions') || checkHasFeature(features, 'u_corrective_actions_inspection'));
    type CAItem = {
        id: string;
        dueDate: string;
        approverId: string;
        responsibleId: string;
        description: string;
        selectDescription?: string;
        finding: string;
        comment: string;
        title: string;
        requireEvidenceFromApp: boolean;
        selected: boolean;
        severity?: ReportDefect['severity'];
        isSubCheckpoint: boolean;
    };
    type CAItemObject = {
        items: CAItem[],
        description: string
    };
    type CAItems = byId<CAItemObject>;
    const toggleHeader = (id: string) => {
        const openItems = [...addArrayItemOrRemoveIfExists(openHeaders, id)];
        setOpenHeaders(openItems);
    };
    const getCheckpoints = () => {
        let chps: ReportCheckpointHeader[];
        const noTitle = intl.formatMessage({ id: 'corrective_actions.checkpoints_wizard.finding.no_title_label' });
        const _openHeaders = [];
        const obj = {};
        if (isInspection) {
            chps = selectedIds && inspectionCheckpoints.filter((cph) => cph.checkpoints.filter((cp) => cp.id === selectedIds[0]))
        } else {
            chps = [...auditCheckpoints];
        }
        const getCheckpointCAObject = (cp, isSubCheckpoint: boolean): CAItem => {
            const twtIt: TextWithTranslation = {
                translations: cp.instruction_text,
                language: null,
                text: cp.instruction_text.C,
            }
            const instructionTextTranslated = translateTextWithTranslation(twtIt, getLocaleLanguageString(intl.locale));
            const cpObj: CAItem = {
                id: cp.id,
                dueDate: null,
                responsibleId: null,
                approverId: null,
                requireEvidenceFromApp: true,
                selected: !checkpointsWithCAP[cp.id] && (cpsWithRemarksIds && cpsWithRemarksIds.includes(cp.id) || selectedIds && selectedIds.includes(cp.id)),
                description: cp.comment2 || '',
                title: instructionTextTranslated || noTitle,
                finding: instructionTextTranslated || intl.formatMessage({ id: 'corrective_actions.checkpoints_wizard.finding.no_title_label' }),
                comment: cp.comment2,
                selectDescription: instructionTextTranslated || intl.formatMessage({ id: 'corrective_actions.defects.wizard.no_description' }),
                isSubCheckpoint,
            }
            return cpObj;
        }

        chps.forEach((cph, i) => {
            const items = [];
            obj[cph.id] = {
                description: cph.title && cph.title.C,
                finding: cph.title && cph.title.C,
            }

            cph.checkpoints.forEach((cp) => {
                const cpObj = getCheckpointCAObject(cp, false);
                items.push(cpObj);
                cp.sub_checkpoints.forEach((subCp) => {
                    const cpObj = getCheckpointCAObject(subCp, true);
                    items.push(cpObj);
                })
            });
            if (items.filter((item) => item.selected).length > 0) {
                _openHeaders.push(i.toString())
            }
            obj[cph.id].items = items;
        });
        setOpenHeaders(_openHeaders);
        updateCAs(obj)
    };

    const getDefects = () => {
        const def = [...defects.critical, ...defects.major, ...defects.minor].filter((def) => selectedIds ? selectedIds.includes(def.id) : def);
        if (def.length > 0) {
            const obj = {};
            const items = [];
            obj['root'] = {};
            def.forEach((rd) => {
                const cpObj: CAItem = {
                    id: rd.id,
                    dueDate: null,
                    responsibleId: null,
                    approverId: null,
                    requireEvidenceFromApp: false,
                    selected: true,
                    selectDescription: rd.description.trim() || intl.formatMessage({ id: 'corrective_actions.defects.wizard.no_description' }),
                    description: rd.description.trim(),
                    finding: getFinding(rd),
                    comment: intl.formatMessage({ id: 'corrective_actions.checkpoints_wizard.no_comment' }),
                    severity: rd.severity,
                    title: '',
                    isSubCheckpoint: false,
                }
                items.push(cpObj);
            })
            obj['root'].items = items;
            updateCAs(obj);
        }
    }
    const getFinding = (d: ReportDefect) => {
        const p = [];
        if (d.type) {
            p.push(d.type.code + '. ' + d.type.name);
        }
        if (d.whole_lot) {
            p.push(intl.formatMessage({ id: 'corrective_actions.defects.wizard.general_defect' }));
        }
        return getStringsSeparatedBy(p, '.');
    }

    useEffect(() => {
        const isApply = selectedIds;
        setResponsibleValue(undefined);
        setApproverValue(undefined);
        setDueDateValue(undefined);
        setRequireEvidenceFromAppValue(true);
        setStep(isApply ? Step.Apply : Step.Select);
        if (itemType === 'checkpoint') {
            getCheckpoints();
        }
        if (itemType === 'defect') {
            getDefects();
        }
        return () => updateCAs({})
    }, [isOpen])

    const setProperty = (property: 'responsibleId' | 'approverId' | 'requireEvidenceFromApp' | 'dueDate' | 'title' | 'finding' | 'selected' | 'description', value: string | boolean, id?: string) => {
        const cas = { ...CAs };
        Object.keys(cas).forEach((k) => {
            const caItem = cas[k];
            caItem.items.forEach((item) => {
                if (id) {
                    if (id === item.id) {
                        item[property as any] = value;
                    }
                } else {
                    item[property as any] = value;
                }
            });
        });
        updateCAs(cas);
    }
    const setApprover = (approverId: string) => {
        setProperty('approverId', approverId);
        setApproverValue(approverId);
    }
    const setResponsible = (responsibleId: string) => {
        setProperty('responsibleId', responsibleId);
        setResponsibleValue(responsibleId);
    }
    const setRequireEvidenceFromApp = (require: boolean) => {
        setProperty('requireEvidenceFromApp', require);
        setRequireEvidenceFromAppValue(require);
    }
    const setSelected = (selected: boolean, id: string) => {
        setProperty('selected', selected, id);
    }
    const setFinding = (finding: string, id: string) => { setProperty('finding', finding, id); }
    const setDescription = (finding: string, id: string) => { setProperty('description', finding, id); }

    // const setDescription = (description: string, id: string) => { setProperty('description', description, id); }
    const setDueDate = (d) => {
        const date = d ? format(d, DEFAULT_DATE_FORMAT2) : null;
        setProperty('dueDate', date)
        setDueDateValue(date)
    }
    const okToSave = step !== Step.Select;

    const save = () => {
        const saveArr = [];
        const cas = { ...CAs };
        Object.keys(cas).forEach((k) => {
            const caItem = cas[k];
            caItem.items.forEach((item) => {
                if (item.selected) {
                    const obj = {};
                    obj['responsible_id'] = item.responsibleId;
                    obj['approver_id'] = item.approverId;
                    obj['due_date_at'] = item.dueDate;
                    obj['finding'] = item.finding;
                    !isInspection && (obj['audit_links'] = [id]);
                    obj['requires_evidence_from_app'] = item.requireEvidenceFromApp;
                    obj['description'] = item.description;
                    itemType === 'checkpoint' ? obj['checkpoint_links'] = [item.id] : obj['defect_link'] = item.id;
                    saveArr.push(obj);
                }
            });
        });
        if (isInspection) {
            dispatch(createCorrectiveActionOnInspection({ inspectionId: id, list: saveArr }));

        } else {
            dispatch(createCorrectiveActionOnAudit({ list: saveArr }));
        }
        clear();
    }

    const cancel = () => {
        clear();
    }

    const clear = () => {
        setStep(Step.Select);
        setIsOpen(false);
        updateCAs({});
    }

    const getTrigger = () => {
        let buttonTextKey;
        if (isInspection) {
            buttonTextKey = selectedIds ? 'corrective_actions.create_ca_from_defect.trigger' : 'corrective_actions.create_ca_from_defects.trigger'
        } else {
            buttonTextKey = selectedIds ? 'corrective_actions.create_ca_from_cp.trigger' : 'corrective_actions.create_ca_from_cps.trigger';
        }
        return <span>{<Popup trigger={
            <Button className={twMerge('space-x-1 px-1', className)} small>
                <Icon name='assignment_turned_in' /><span><FormattedMessage id={buttonTextKey} /></span>
            </Button>}>
            <FormattedMessage id={itemType === 'defect' ? 'corrective_actions.create_ca_from_defects.header' : 'corrective_actions.checkpoints_wizard.trigger.popup_text'} /></Popup>}</span>
    }

    const getDescriptionAndFinding = (description: string, finding: string) => {
        if (description && finding) {
            return description + '. ' + finding;
        }
        return description ? description : finding;
    }

    const modal =

        <Modal
            open={isOpen}
            onClose={() => cancel()}
            onOpen={() => {
                setIsOpen(true);
            }}
            trigger={getTrigger()}
        >
            <Modal.Header><FormattedMessage id={itemType === 'checkpoint' ? 'corrective_actions.create_ca_from_cps.header' : 'corrective_actions.create_ca_from_defects.header'} /></Modal.Header>
            <Modal.Content>
                {step === Step.Apply && <div className='flex flex-col pb-4  border-b mb-2 -mx-6 px-6'>
                    <div className='pb-4'><FormattedMessage id={itemType === 'checkpoint' ? 'corrective_actions.create_ca_from_cps.set_approver_etc' : 'corrective_actions.create_ca_from_defects.set_approver_etc'} /></div>

                    <div className='flex space-x-2 pb-2'>
                        <div className='w-4/12  space-y-1 text-secondary'>
                            <label><FormattedMessage id='corrective_actions.responsible' /></label>
                            <UsersSelector
                                value={[responsibleId]}
                                placeholder={intl.formatMessage({ id: 'corrective_actions.create_modal.responsible.placeholder' })}
                                handleChange={(value) => setResponsible(value.value)}
                                allowMultipleSelection={false}
                                disabled={false}
                                showEmailInSelector
                                showUnassigned
                            />
                        </div>
                        <div className='w-4/12 space-y-1 text-secondary'><label><FormattedMessage id='corrective_actions.approver' /></label>
                            <UsersSelector
                                value={[approverId]}
                                placeholder={intl.formatMessage({ id: 'corrective_actions.create_modal.approver.placeholder' })}
                                handleChange={(value) => setApprover(value.value)}
                                allowMultipleSelection={false}
                                disabled={false}
                                showEmailInSelector
                                showUnassigned
                            />
                        </div>
                        <div className='w-4/12  space-y-1 text-secondary mb-2'>
                            <label><FormattedMessage id='corrective_actions.details.due_date' /></label>
                            <DatePicker3 date={dueDate} handleChange={(d) => setDueDate(d)} />
                        </div>
                    </div>
                    <Checkbox label={intl.formatMessage({ id: 'corrective_actions.checkpoints_wizard.require evidence from app' })} checked={requireEvidenceFromApp} onChange={() => setRequireEvidenceFromApp(!requireEvidenceFromApp)} />
                </div>}

                <div className='flex flex-col'>
                    {step === Step.Select && <div className='pb-4'>
                        {selectedItemsCnt === 0 ? <FormattedMessage id={createOnType === CorrectiveActionsFromDefectOrCheckpointsType.Audit ? 'corrective_actions.create_ca_from_cps.select_cps' : 'corrective_actions.create_ca_from_cps.select_defects'} /> : <FormattedMessage id={isCheckpoint ? 'corrective_actions.create_ca_from_cps.selected_cps' : 'corrective_actions.create_ca_from_cps.selected_defects'} values={{ cnt: selectedItemsCnt }} />}
                    </div>}
                </div>
                {createOnType === CorrectiveActionsFromDefectOrCheckpointsType.Audit && step === Step.Select && Object.values(CAs).map((chpH, i) => {
                    const hasRemarks = openHeaders.includes(i.toString());
                    const header = <div className='pb-1 font-medium cursor-pointer py-1 flex space-x-1' onClick={() => toggleHeader(i.toString())}><Icon name={hasRemarks ? 'expand_more' : 'chevron_right'} /><div>{chpH.description}</div>{!hasRemarks && <div>({chpH.items.length})</div>}</div>;
                    return <div className='pb-4' key={'chpH_' + chpH.description + i}>
                        {step === Step.Select && header}
                        {hasRemarks && <div className='flex flex-col space-y-2'>
                            <div className='flex flex-col'>{chpH.items.map((cp) => {
                                return <div key={'cp_' + cp.id} className={twMerge(' px-1 flex hover:bg-gray-200 items-center cursor-pointer')}>
                                    <div className='flex space-x-2 items-center w-full' onClick={() => setSelected(!cp.selected, cp.id)}>
                                        <Checkbox className='text-secondary' checked={cp.selected} />
                                        {
                                            checkpointsWithCAP[cp.id] ?
                                                <Popup trigger={
                                                    <div className='py-1 flex space-x-1'>
                                                        <div>{cp.selectDescription}</div>
                                                        <Icon name='assignment_turned_in' />
                                                    </div>
                                                } on='hover'>
                                                    <FormattedMessage id='corrective_actions.create_ca_from_cp.already_has_cap' />
                                                </Popup>
                                                :
                                                <div className='py-1 flex space-x-1'>
                                                    {cp.selectDescription}
                                                </div>
                                        }
                                    </div>
                                </div>
                            })}</div>
                        </div>}

                    </div>
                })}
                {isInspection && step === Step.Select && Object.values(CAs).map((chpH, i) => {
                    const header = <div className='pb-1 font-medium cursor-pointer py-1 flex space-x-1'><div>{chpH.description}</div></div>;
                    return <div className='pb-4' key={'chpH_' + chpH.description + i}>{header}
                        {<div className='flex flex-col space-y-2'>
                            <div className='flex flex-col'>{chpH.items.map((cp) => {
                                return <div key={'cp_' + cp.id} className={twMerge(' px-1 flex hover:bg-gray-200 items-center cursor-pointer')}>
                                    <div className='flex space-x-2 items-center w-full' onClick={() => setSelected(!cp.selected, cp.id)}>
                                        <Checkbox className='text-secondary' checked={cp.selected} />
                                        {itemType === 'defect' && <SeverityLabel severity={cp.severity} className='py-1 sm:w-16 sm:text-xs' />}
                                        <div className='py-1 flex space-x-1 items-center'><div className=''>
                                            {cp.isSubCheckpoint && <span><FormattedMessage id='corrective_actions.create_ca_from_cps.sub_checkpoint' /><span className='mx-2'>&gt;</span></span>}
                                            {isCheckpoint ? cp.finding : getDescriptionAndFinding(cp.description, cp.finding)}
                                        </div></div>
                                    </div>
                                </div>
                            })}</div>
                        </div>}
                    </div>
                })}
                {step === Step.Apply && <div className='py-4'>
                    <div className='flex space-x-1 font-medium'>
                        <div className='w-1/2 justify-between'><FormattedMessage id='corrective_actions.checkpoints_wizard.apply.finding' /></div>
                        <div className='w-1/2'><FormattedMessage id={'corrective_actions.checkpoints_wizard.apply.defect_description'} /></div>
                    </div >
                    {
                        Object.values(CAs).map((chpH, i) => {
                            return <div key={'cph_' + i} >{chpH.items.map((cp) => {
                                const isFindingEditable = findingEditable === cp.id;
                                const isDescriptionEditable = descriptionEditable === cp.id;
                                const descriptionText = cp.description;
                                const descriptionPlaceholder = intl.formatMessage({ id: 'corrective_actions.checkpoints.wizard.enter_description' });
                                const noDescription = isUndefinedOrNullOrEmptyString(descriptionText);
                                return cp.selected && <div className='flex items-center space-x-2 hover:bg-gray-200 -mx-1 px-1'>
                                    <div className={twMerge('py-1 flex w-1/2 justify-between items-center', !findingEditable && ' border-transparent border-b-2 border-dotted hover:border-gray-400')}>
                                        <div className='flex w-full'>
                                            {isFindingEditable && <div className='flex w-full py-1'>
                                                <TextArea
                                                    placeholder='Enter description'
                                                    rows={Math.ceil(cp.finding.length / 60)}
                                                    className='border p-1 rounded-sm w-full flex text-base' autoFocus type='text'
                                                    onFocus={(e: any) => {
                                                        e.currentTarget.scrollTo(0, 0);
                                                        e.currentTarget.selectionStart = 0;
                                                        e.currentTarget.selectionEnd = e.currentTarget.value.length;
                                                    }} value={cp.finding} onChange={(item) => setFinding(item.currentTarget.value, cp.id)} onBlur={() => toggleFindingEditable(undefined)} /></div>}
                                            {!isFindingEditable && <div className='hover:cursor-text py-1 flex space-x-1 cursor-text w-full' onClick={() => toggleFindingEditable(cp.id)}><div className=''>{cp.finding}</div></div>}
                                        </div>
                                        {!isFindingEditable && <Popup trigger={<span onClick={() => toggleFindingEditable(cp.id)}><Icon name='edit' className='text-secondary' /></span>}><FormattedMessage id='corrective_actions.checkpoints_wizard.click_to_edit_finding_text' /></Popup>}
                                    </div>

                                    <div className={twMerge('py-1 flex w-1/2 justify-between items-center', !descriptionEditable && ' border-transparent border-b-2 border-dotted hover:border-gray-400')}>
                                        <div className='flex w-full'>
                                            {isDescriptionEditable && <div className='flex w-full py-1'>
                                                <TextArea rows={Math.ceil(cp.description.length / 60)} className='border p-1 rounded-sm w-full flex text-base' autoFocus type='text' onFocus={(e: any) => {
                                                    e.currentTarget.scrollTo(0, 0);
                                                    e.currentTarget.selectionStart = 0;
                                                    e.currentTarget.selectionEnd = e.currentTarget.value.length;
                                                }} value={descriptionText} onChange={(item) => setDescription(item.currentTarget.value, cp.id)} onBlur={() => toggleDescriptionEditable(undefined)} /></div>}
                                            {!isDescriptionEditable && <div className={twMerge('hover:cursor-text py-1 flex space-x-1 cursor-text w-full', noDescription && 'text-input-placeholder')} onClick={() => toggleDescriptionEditable(cp.id)}><div className=''>{noDescription ? descriptionPlaceholder : descriptionText}</div></div>}
                                        </div>
                                        {!isDescriptionEditable && <Popup trigger={<span onClick={() => toggleDescriptionEditable(cp.id)}><Icon name='edit' className='text-secondary' /></span>}><FormattedMessage id='corrective_actions.checkpoints_wizard.click_to_edit_finding_text' /></Popup>}
                                    </div>
                                </div>
                            })}</div>
                        })}
                </div>
                }
            </Modal.Content>
            <Modal.Actions>
                <div className='flex justify-between flex-row-reverse items-center mb-4 sm:mb-0'>
                    <div className='flex'>
                        {step === Step.Select && <Button disabled={selectedItemsCnt === 0} primary className='pr-2 pl-4' onClick={() => setStep(1)}><FormattedMessage id='corrective_actions.checkpoints_wizard.next' /><Icon className='ml-1' name='chevron_right' /></Button>}
                        {step === Step.Apply && <Button className='ml-8' disabled={!okToSave} onClick={() => save()} primary><FormattedMessage id='corrective_actions.create_ca_from_cps.create' /></Button>}
                        <Button onClick={() => cancel()}><FormattedMessage id='corrective_actions.create_ca_from_cps.cancel' /></Button>
                    </div>
                    {step === Step.Apply && <Button disabled={selectedItemsCnt === 0} className='pl-2 pr-4' onClick={() => setStep(0)}><Icon name='chevron_left' className='' /><FormattedMessage id='corrective_actions.checkpoints_wizard.previous' /></Button>}
                </div>
            </Modal.Actions>
        </Modal >
    useEffect(() => {
        setSelectedItemsCnt(Object.values(CAs).map((ca) => ca.items.filter((item) => (item.selected)).length).reduce((a, b) => a + b, 0));
    }, [CAs])

    return canCreateCorrectiveAction && modal;
}
export default CorrectiveActionsFromCheckpointsOrDefectsWizard;

function buildMapOfCheckpointWithCAP(correctiveActions: CorrectiveAction[]): { [key: string]: boolean } {
    const map = {};
    correctiveActions?.forEach(ca => {
        ca.checkpoint_links?.forEach(link => {
            if (link?.checkpoint_id) {
                map[link.checkpoint_id] = true;
            }
        })
    })
    return map;
}
