import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { Sizes } from '../../../base/components/types';
import { useTranslation } from '../../../base/hooks';
import Button, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import EmptyState, { EmptyStateType } from '../../../base/ui/components/emptyState/EmptyState';
import InfoBox from '../../../base/ui/components/infoBox/InfoBox';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import EditAction from '../../../base/ui/components/util/EditAction';
import { DetailedTag } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { complianceAddTagsToRequirement } from '../complianceSlice';
import ComplianceTagsBrowser, { addOrRemoveTag } from './ComplianceTagsBrowser';

type Props = {
    className?: string;
    requirementId: string;
    tags: DetailedTag[];
    onUpdateTags(tags: DetailedTag[]): void;
};

const ComplianceRequirementDetailedTags = (props: Props): React.ReactElement => {
    const { className, requirementId, onUpdateTags, tags } = props;
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState(false);
    const [confirm, setConfirm] = useState(false);
    
    useEffect(() => {
        setConfirm(confirm);
    }, [confirm]);

    useEffect(() => {
        if (props.tags?.length === 0 && !edit) {
            setEdit(true);
        }
    }, [props.tags])
    const done = () => {
        setEdit(false);
        setConfirm(false);
    }
    const confirmDialog = <QModal
        onClose={() => setConfirm(false)}
        isOpen={confirm}
        width={500}
        header={useTranslation('compliance.requirements.add_tags_confirmation.header')}
        content={
            <InfoBox
                header={useTranslation('compliance.requirements.add_tags_confirmation.note_text')}
                message={<div className='space-y-4'>
                    {useTranslation('compliance.requirements.add_tags_confirmation_text')}
                </div>} />
        }
        actionItems={[
            { type: ActionTypes.Action, text: useTranslation('compliance.requirements.add_tags_confirmation.action_text'), buttonType: ButtonTypes.Primary, event: () => setEdit(true) },
            { type: ActionTypes.CancelAction, text: useTranslation('globals.cancel'), buttonType: ButtonTypes.Plain, event: () => { setConfirm(false) } },
        ]}
    />
    const currentTags = <ComplianceTagsBrowser
        tags={tags}
        isModal={false}
        isOpen={edit}
        editable={edit}
        onSave={(tagsFoo) => {
            dispatch(complianceAddTagsToRequirement({ requirementId, tags }));
            setEdit(false);
        }}
        onSelected={(tag) => {
            const newTags = addOrRemoveTag([...tags], tag)
            dispatch(complianceAddTagsToRequirement({ requirementId, tags: newTags }));
            onUpdateTags(newTags as DetailedTag[]);

        }} />
    const tagsContent =
        <div className='gap-y-2 flex w-full items-top justify-between border-b pb-4 px-4'>
            <div className={twMerge('w-7/12 flex flex-col', edit && 'gap-y-4')}>
                <KeyValuePair
                    keyItem={{ name: useTranslation('compliance.requirements.tags_title') }}
                    value={{
                        value: currentTags
                    }} />
                {tags?.length === 0 && !edit && <div className='cursor-pointer' onClick={() => setEdit(true)}><EmptyState headerClassName='justify-start' type={EmptyStateType.Inline} title={useTranslation('compliance.requirements.no_tags_added')} /></div>}
                {edit && <Button className='self-start' size={Sizes.Small} buttonType={ButtonTypes.Primary}
                    onPress={() => {
                        setEdit(false);
                        setConfirm(false);
                    }}><FormattedMessage id='globals.done_editing' /></Button>}
            </div>
            <EditAction edit={edit} onEditUpdated={!edit ? setConfirm : done} />
            {confirmDialog}
        </div>

    return tagsContent;
}
export default ComplianceRequirementDetailedTags;
