import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import { ComponentOrStringType, byId } from '../../../base/types';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import Icon from '../../../base/ui/components/icons/Icon';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { usePrevious } from '../../../base/utils';
import { DetailedDocumentTemplate, DocumentTemplateScopeProductTypeEnum, EditDocumentTemplateExpirationRule, ExpirationFromFileTypeEnum, FrequencyFrequencyTypeEnum, FrequencyTypeEnum, NoExpirationTypeEnum, PerOrderTypeEnum, SharedDocumentTemplateResponsible } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { complianceRequirementSetApproveResponsible, complianceRequirementSetUploadResponsible, complianceUpdateExpiryFrequency } from '../complianceSlice';
import { getComplianceRequirementSelector } from '../selectors/ComplianceSelectors';
import { convertSharedResponsibleToRequesFormat } from './ComplianceRequirementAddDocumentSpecifictionModal';
import { default as ComplianceRequirementDocumentApprovalResponsible, default as ComplianceRequirementDocumentResponsible } from './ComplianceRequirementDocumentApprovalResponsible';
import ComplianceRequirementDocumentSpecificationFrequencySelector from './ComplianceRequirementDocumentSpecificationFrequencySelector';

type Props = {
    className?: string;
    documentSpecification: DetailedDocumentTemplate;
    viewType: EditViewType;
};

export type EditViewType = 'approval_responsible' | 'upload_responsible' | 'expiry_rule';

const expiryIconsMap: byId<Icons> = {
    [FrequencyTypeEnum.Frequency]: 'expiry_renewal',
    [NoExpirationTypeEnum.NoExpiration]: 'expiry_never',
    [PerOrderTypeEnum.PerOrder]: 'expiry_by-order',
    [ExpirationFromFileTypeEnum.ExpirationFromFile]: 'expiry_time-based',

}
const scopeIconsMap: byId<Icons> = {
    [DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope]: 'object_product',
    // [DocumentTemplateScopeSupplierOrProductionUnitTypeEnum.DocumentTemplateSupplierOrProductionUnitScope]: 'user_supplier',
}
const ComplianceRequirementEditDocumentSpecification = (props: Props): React.ReactElement => {
    const { className, documentSpecification } = props;
    const [viewType, setViewType] = useState<EditViewType>(undefined);
    const modalData = useSelector(getShowModalSelector)['editDocumentSpecification_' + documentSpecification.document_template_id];
    const [documentApprovalResponsibles, setDocumentApprovalResponsibles] = useState<SharedDocumentTemplateResponsible>(null);
    const [documentUploadResponsibles, setDocumentUploadResponsibles] = useState<SharedDocumentTemplateResponsible>(null);

    const location = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const requirementData = useSelector(getComplianceRequirementSelector);
    const [edit, setEdit] = useState(false);
    const [editModal, showEditModal] = useState(false);
    const [approvalData, setApprovalData] = useState<SharedDocumentTemplateResponsible>(documentSpecification.approve_responsible)
    const [uploadResponsible, setUploadResponsible] = useState<SharedDocumentTemplateResponsible>(documentSpecification.upload_responsible)
    const [expiryRule, setExpiryRule] = useState(documentSpecification.expiration_rule)
    const [expiryFrequencyValue, setExpiryFrequencyValue] = useState(0);
    const prevApproval = usePrevious(approvalData);
    const prevUpload = usePrevious(uploadResponsible);

    const save = () => {

        switch (viewType) {
            case 'approval_responsible': {
                const approvalResponsible = convertSharedResponsibleToRequesFormat(approvalData);
                dispatch(complianceRequirementSetApproveResponsible({ ...approvalResponsible, suppliers: approvalResponsible.supplier, document_template_id: documentSpecification.document_template_id, requirement_id: documentSpecification.requirement_id }));
                break;
            }
            case 'upload_responsible': {
                const approvalResponsibleRequest = convertSharedResponsibleToRequesFormat(uploadResponsible);
                dispatch(complianceRequirementSetUploadResponsible({ ...approvalResponsibleRequest, suppliers: approvalResponsibleRequest.supplier, document_template_id: documentSpecification.document_template_id, requirement_id: documentSpecification.requirement_id }));
                break;
            }
            case 'expiry_rule': {
                dispatch(complianceUpdateExpiryFrequency({ requirementId: documentSpecification.requirement_id, documentSpecificationId: documentSpecification.document_template_id, frequency_value: expiryFrequencyValue }));
                break;
            }
        }
        cancel();
    }
    const cancel = () => {
        dispatch(showModal({ id: 'editDocumentSpecification_' + documentSpecification.document_template_id, show: false }));
    }
    const fakeInputStyle = 'flex rounded-md flex-1 border-input border bg-input text-base px-3 py-2 cursor-pointer';

    useEffect(() => {
        setViewType(modalData?.data?.type);

    }, [modalData?.data])
    const translate = (key: string): string => {
        return intl.formatMessage({ id: key });
    }
    const getExpirationRule = (rule: EditDocumentTemplateExpirationRule) => {
        return <div className='flex gap-x-1'><Icon name={expiryIconsMap[rule.type]} />{translate('compliance.requirements.expiration_rule.' + documentSpecification.expiration_rule.type + '.text')}</div>
    }
    const getScope = (scope) => {
        return <div className='flex gap-x-1'><Icon name={scopeIconsMap[scope.type]} />{translate('compliance.requirements.document_template_scope.' + documentSpecification.scope.type)}</div>
    }
    const getApprovalResponsibles = () => {
        return null;
    }
    const editApprovalResponsible =
        <div className='space-y-2'>
            <div className='font-bold'>Responsible for approval</div>
            <ComplianceRequirementDocumentApprovalResponsible requirementId=''
                onUpdated={(res) => {
                    // setDocumentApprovalResponsibles(res);
                }} />
        </div>
    type ViewMapTypes = Partial<Record<EditViewType, ComponentOrStringType>>;

    const contentMap: ViewMapTypes = {
        expiry_rule: <div><ComplianceRequirementDocumentSpecificationFrequencySelector selectedValue={modalData?.data?.documentSpecification.expiration_rule.frequency_value}
            onValueChanged={(v) => {
                setExpiryRule({
                    ...expiryRule,
                    frequency_value: v,
                    frequency_type: FrequencyFrequencyTypeEnum.Month,
                    type: FrequencyTypeEnum.Frequency,

                })
                setExpiryFrequencyValue(v);
            }} /></div>,
        upload_responsible: <ComplianceRequirementDocumentResponsible requirementId=''
            responsibleData={(uploadResponsible)}
            onUpdated={(r) => {
                setUploadResponsible(r);
            }}

        // onSave={(f) => console.log('onSave ', f)} 
        />,
        approval_responsible: <ComplianceRequirementDocumentResponsible requirementId=''
            responsibleData={(approvalData)}
            onUpdated={(d) => {
                setApprovalData(d);
            }}

        // onSave={(f) => console.log('onSave ', f)} 
        />
    }
    const headerMap: ViewMapTypes = {
        approval_responsible: intl.formatMessage({ id: 'compliance.requirements.document_template.edit_document_approval' }),
        upload_responsible: intl.formatMessage({ id: 'compliance.requirements.document_template.edit_upload_responsible' }),
        expiry_rule: intl.formatMessage({ id: 'compliance.requirements.document_template.edit_expiry_rule' }),
    }
    const actionsMap = {
        create: {
            ok: intl.formatMessage({ id: 'compliance.product.activate.add_a_due_date' }),
            cancel: intl.formatMessage({ id: 'globals.cancel' }),
        },
        edit: {
            ok: intl.formatMessage({ id: 'globals.done' }),
            cancel: intl.formatMessage({ id: 'globals.cancel' }),
        },
        noOrdersYet: {
            ok: intl.formatMessage({ id: 'compliance.product.activate.add_a_due_date' }),
            cancel: intl.formatMessage({ id: 'globals.cancel' }),
        }
    }

    const modal = <QModal
        width={500}
        isOpen={modalData?.show}
        onOpenChange={(open) => !open && cancel()}
        onClose={() => cancel()}
        header={headerMap[viewType]}
        content={<>
            {contentMap[viewType]}
        </>}
        actionItems={[
            {
                type: ActionTypes.Action, text: translate('globals.save'), buttonType: ButtonTypes.Primary, event: save,
            },
            { type: ActionTypes.CancelAction, text: translate('globals.cancel'), buttonType: ButtonTypes.Plain, event: cancel },
        ]}
    />
    return modal;
}
export default ComplianceRequirementEditDocumentSpecification;
