import classnames from 'classnames';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dimmer, Dropdown, Form, Input, Loader } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Image from '../../../base/components/Image';
import DatePicker from '../../../base/components/basic/DatePicker3';
import Icon from '../../../base/components/basic/Icon';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import Button from '../../../base/ui/components/buttons/Button';
import { isUndefinedOrNullOrEmptyString, useClickOutsideTrigger } from '../../../base/utils';
import { CreateOrder, ShallowProduct } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import { ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import { getIsFetchingSelector } from '../../groups/selectors';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { createOrder, getComplianceProductsList, getSuppliers } from '../complianceSlice';
import { getCompliancePaginatedProductsSelector, getComplianceSuppliersSelector } from '../selectors/ComplianceSelectors';

type ComplianceCreateOrderProps = {
    className?: string;
    triggerClassName?: string;
    label?: string;
};

type DraftOrder = {
    deadline: string,
    number: string,
    products: string[]
}

const ComplianceCreateOrder = (props: ComplianceCreateOrderProps): React.ReactElement => {
    const { className } = props;
    const dispatch = useAppDispatch();
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const intl = useIntl();
    const formatMessage = intl.formatMessage;

    const suppliers = useSelector(getComplianceSuppliersSelector);

    useEffect(() => {
        dispatch(getSuppliers())
    }, [])

    // TODO, should also be able to select production units, not only suppliers.
    const [chosenSuppliers, setChosenSuppliers] = useState<{ [productId: string]: string }>({});

    function chooseSupplier(product_id, supplier_id) {
        setChosenSuppliers({ ...chosenSuppliers, [product_id]: supplier_id })
    }

    console.log(chosenSuppliers)

    const isFetching = useSelector(getIsFetchingSelector);
    const paginatedProducts = useSelector(getCompliancePaginatedProductsSelector);
    const products = paginatedProducts?.products;
    const [selectedProducts, setSelectedProducts] = useState<ShallowProduct[]>([]);
    const [searchText, setSearchText] = useState('');

    const [searchedProducts, setSearchedProducts] = useState(products);
    const hasProducts = searchedProducts?.length > 0
    const initialProduct: DraftOrder = {
        number: '',
        deadline: '',
        products: []
    };
    const draft = window.localStorage.getItem('order-draft');
    const orderDraft = draft ? JSON.parse(draft) as DraftOrder : null;
    const [order, setOrder] = useState<DraftOrder>(orderDraft || initialProduct);
    const checkValidation = () => {
        return (order.number && order.products?.length > 0) && !isUndefinedOrNullOrEmptyString(order.deadline) && !isUndefinedOrNullOrEmptyString(chosenSuppliers);
    }
    const [isSaveable, setIsSaveable] = useState(checkValidation());
    const [showSelector, setShowSelector] = useState(false);
    useClickOutsideTrigger(dropdownRef, () => setShowSelector(false));

    const handleUpdate = (event, item) => {
        const orderDataCopy = Object.assign({}, order);
        orderDataCopy[item.name] = item.value;
        setOrder(orderDataCopy);
        storeDraft(orderDataCopy);
    }

    const handleSave = () => {
        setIsSaveable(false);
        const createOrderParams: CreateOrder = {
            deadline: order.deadline,
            number: order.number,
            products: order.products.map(function (p) {
                return {
                    product_id: p,
                    // TODO : Compliance Backend need to add proper types to `supper_type`.
                    supplier_type: 'supplier',
                    supplier_id: chosenSuppliers[p]
                }
            })
        }

        dispatch(createOrder(createOrderParams));
    }

    const close = () => {
        history.back();
        setOrder(initialProduct);
        removeDraft();
    }

    const storeDraft = (p: DraftOrder) => {
        window.localStorage.setItem('order-draft', JSON.stringify(p));
    }

    const removeDraft = () => {
        window.localStorage.removeItem('order-draft');
    }

    const isProductSelected = (id: string): boolean => {
        return selectedProducts.findIndex(p => p.product_id == id) != -1;
    }

    const selectProduct = (product: ShallowProduct) => {
        let _selected;
        if (isProductSelected(product.product_id)) {
            _selected = selectedProducts.filter(p => p.product_id != product.product_id);
        } else {
            _selected = [...selectedProducts, product];
        }
        const selectedProductsMap = {};
        _selected.forEach(p => {
            selectedProductsMap[p.product_id] = p;
        });

        setSelectedProducts(_selected);
        setOrder({
            ...order,
            products: _selected.map(p => p.product_id)
        })
        setSearchedProducts(products.filter(p => !selectedProductsMap[p.product_id]));
        setSearchText('');
    }

    const searchForProduct = (text: string) => {
        setSearchText(text);
        const selectedProductsMap = {};
        selectedProducts.forEach(p => {
            selectedProductsMap[p.product_id] = p;
        });
        const displayProducts = products.filter(p => !selectedProductsMap[p.product_id]);
        if (!text) {
            setSearchedProducts(displayProducts);
            return;
        }
        const searchProduct = displayProducts.filter(p => p.product_name.toLowerCase().includes(text.toLowerCase()) || p.product_number.toLowerCase().includes(text.toLowerCase()));
        setSearchedProducts(searchProduct);
    }

    useEffect(() => {
        dispatch(getComplianceProductsList({status: 'all'}));
    }, [])
    useEffect(() => {
        setIsSaveable(checkValidation());
    }, [order])

    const createOrderSection = <ContentSection
        content={<Fragment>
            <div className={classnames(className, 'pb-6')}>
                <h3><FormattedMessage id='compliance.order_list.create_order.title' /></h3>
                <Form >
                    <Form.Field width={16} inline required>
                        <label><FormattedMessage id='compliance.order_list.create_order.number' /></label>
                        <Input autoFocus={true} autoComplete='off' type='text' name='number' value={order.number} onChange={(e, i) => {
                            handleUpdate(e, i)
                        }} />
                    </Form.Field>
                    <Form.Field width={16} inline>
                        <label><FormattedMessage id='compliance.order_list.create_order.products' /></label>


                        {
                            products?.length == selectedProducts.length && selectProduct.length != 0 ?
                                <div className='py-8 px-4'><FormattedMessage id='compliance.create_order.all_products_selected' /></div> :
                                <div>
                                    <div ref={dropdownRef} className={'ui fluid multiple search selection dropdown my-4'}>
                                        <div className='flex flex-row' onClick={(event) => {
                                            setShowSelector(true);
                                            inputRef.current.focus();
                                            event.stopPropagation();
                                        }}>
                                            <div className='flex flex-wrap'>
                                                <input
                                                    style={{ border: 'none', width: '300px' }} type='text'
                                                    onChange={(event) => searchForProduct(event.target.value)}
                                                    placeholder={'Product name or product number'}
                                                    onFocus={() => setShowSelector(true)}
                                                    ref={inputRef}
                                                    value={searchText}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {showSelector && searchedProducts && hasProducts &&
                                                <div className='py-4'>
                                                    <ListViewHeader className='w-full hidden md:flex md:mt-0 md:mb-0 md:py-0 text-sm border-b border-default bg-highlight-light-green' items={[
                                                        { label: undefined, className: twMerge(' py-2 w-1/16'), key: 'spacer.1' },
                                                        { label: formatMessage({ id: 'compliance.order_list.product.product_name' }), className: twMerge(' py-2 w-8/16'), key: 'item_name' },
                                                        { label: formatMessage({ id: 'compliance.order_list.product.product_number' }), className: twMerge(' py-2 w-5/16'), key: 'item_no' },
                                                        { label: undefined, className: twMerge(' py-2 w-3/16'), key: 'deadline' },

                                                    ]} />
                                                    <div style={{ maxHeight: '250px' }} className="overflow-y-scroll">

                                                        <ListViewLines className=' border-b-0'>
                                                            {searchedProducts.map((product, i) => {
                                                                return <ListViewLine onClick={() => selectProduct(product)} key={i} className={`${isProductSelected(product.product_id) ? 'bg-green-100' : 'bg-white'} border-b cursor-pointer items-center hover:bg-highlight-green`}>
                                                                    <ListViewItem className={classnames('pt-4 md:pt-0 order-last md:order-first w-full md:w-1/16')}>
                                                                        {product && product.product_image && <Image squared className='hidden md:flex w-24 object-contain  border-2' url={product.product_image.image.image_url} />}
                                                                    </ListViewItem>
                                                                    <ListViewItem className='w-full md:w-8/16 font-bold md:font-normal'>{product.product_name}</ListViewItem>
                                                                    <ListViewItem className='w-full md:w-5/16 font-bold md:font-normal'>{product.product_number}</ListViewItem>
                                                                    <ListViewItem className='w-full md:w-3/16 font-bold md:font-normal items-center'>
                                                                        {isProductSelected(product.product_id) ? <Button danger><FormattedMessage id='compliance.create_order.deselect' /></Button> : <Button primary><FormattedMessage id='compliance.create_order.select' /></Button>}
                                                                    </ListViewItem>
                                                                </ListViewLine>
                                                            })}
                                                        </ListViewLines>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            products?.length != selectedProducts.length && selectedProducts.length > 0 && <div className='flex flex-row my-4'>
                                <div className='text'><FormattedMessage id='compliance.create_order.cnt_products_selected' values={{ cnt: selectedProducts.length }} /></div>
                            </div>
                        }
                        {selectedProducts.map((product, i) => {
                            return <ListViewLine key={i} className='bg-white border-b cursor-pointer items-center hover:bg-highlight-green'>
                                <ListViewItem className={classnames('pt-4 md:pt-0 order-last md:order-first w-full md:w-2/16')}>
                                    {product && product.product_image && <Image squared className='hidden md:flex w-24 object-contain  border-2' url={product.product_image.image.image_url} />}
                                </ListViewItem>
                                <ListViewItem className='w-full md:w-7/16 font-bold md:font-normal'>{product.product_name}</ListViewItem>
                                <ListViewItem className='w-full md:w-6/16 font-bold md:font-normal'>{product.product_number}</ListViewItem>
                                <ListViewItem className='w-full md:w-3/16 font-bold md:font-normal items-center'>
                                    <Button danger onPress={() => selectProduct(product)}><FormattedMessage id='compliance.create_order.deselect' /></Button>
                                </ListViewItem>

                                <ListViewItem>
                                    <div>
                                        <Dropdown
                                            className='w-full'
                                            options={suppliers.map(s => {
                                                return {
                                                    value: s.id,
                                                    text: s.name,
                                                    description: s.number
                                                }
                                            })}
                                            selection
                                            onChange={(e, item) => { chooseSupplier(product.product_id, item.value.toString()) }} />
                                    </div>

                                </ListViewItem>
                            </ListViewLine>
                        })}


                    </Form.Field>
                    <Form.Field width={16} inline>
                        <label><FormattedMessage id='compliance.order_list.create_order.deadline' /></label>
                        <DatePicker
                            pastDates={true}
                            date={(!isUndefinedOrNullOrEmptyString(order.deadline) && new Date(order.deadline) || null)}
                            handleChange={(d) => {
                                setOrder({
                                    ...order,
                                    deadline: format(d, DEFAULT_DATE_FORMAT2)
                                });
                            }}
                        />
                    </Form.Field>

                    <Form.Field width={16} inline>
                        <div className='flex flex-row justify-start my-8'>
                            <Button onPress={close}><FormattedMessage id='globals.cancel' /></Button>
                            <Button className={twMerge('mx-4', !isSaveable && 'cursor-default')} data-test-id='button-create-product-save' primary disabled={!isSaveable} onPress={handleSave}><FormattedMessage id='globals.save' /></Button>
                        </div>
                    </Form.Field>
                </Form>
            </div>
        </Fragment>}
    />




    return <PageContainer>
        <Dimmer active={isFetching} inverted>
            <Loader className='dimLoader'><FormattedMessage id='inspections.loading_data_please_wait' /></Loader>
        </Dimmer>
        <div className='flex justify-center'>
            <div className='w-full md:w-9/12 order-last md:order-first'>
                {createOrderSection}
                <Link className='link noUnderline items-center flex' to={'/compliance/orders'}><Icon name='chevron_left' className='text-xl' /><FormattedMessage id='compliance.create_order.back' /></Link>
            </div>
        </div>
    </PageContainer >
}

export default ComplianceCreateOrder;

