import React, { useState } from 'react';

import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { TextArea } from '../../../base/ui/components/TextArea';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import Container from '../../../base/ui/components/containers/Container';
import { Field } from '../../../base/ui/components/fields/Field';
import InvalidFieldMessage from '../../../base/ui/components/labels/InvalidFieldMessage';
import Label from '../../../base/ui/components/labels/Label';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { createRequirement } from '../complianceSlice';

type Props = {
    cancel(): void;
};

const ComplianceCreateRequirementModal = (props: Props): React.ReactElement => {
    const { cancel } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [name, setName] = useState(null);
    const [date, setDate] = useState(null);
    const [description, setDescription] = useState(null);
    const [errorMessage, setErrorMessage] = useState({ date: null, name: null });

    const checkValidation = (type?: string) => {
        const t = !type ? 'all' : type;
        const nameValid = !isUndefinedOrNullOrEmptyString(name);
        const dateValid = !isUndefinedOrNullOrEmptyString(date);
        const dateError = translate('compliance.requirements.create.requirement_start_date.error');
        const nameError = translate('compliance.requirements.create.requirement_name.error');

        if (t === 'name') {
            setErrorMessage({
                ...errorMessage,
                name: nameValid ? null : nameError,
            })
            return dateValid && nameValid;
        }
        if (t === 'date') {
            setErrorMessage({
                ...errorMessage,
                date: dateValid ? null : dateError,
            })
            return date && dateValid;
        }
        setErrorMessage({
            ...errorMessage,
            date: dateValid ? null : dateError,
            name: nameValid ? null : nameError,
        })
        return dateValid && nameValid;
    }
    const save = () => {
        if (checkValidation()) {
            dispatch(createRequirement({ name, description, start_date: date, end_date: null}));
            setErrorMessage({ date: null, name: null });
        }
    }

    const translate = (key) => {
        return intl.formatMessage({ id: key });
    }

    const createModal = <Modal
        onOpenChange={(c) => !c && cancel()}
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'compliance.requirements.create.create_requirement_action' }), stayOpen: true, event: save, /* disabled: isUndefinedOrNullOrEmptyString(productName) || isUndefinedOrNullOrEmptyString(productNumber) */ },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: cancel },
        ]}
        isOpen={true}
        width={500}
        header={translate('compliance.requirements.create.create_header')}
    >
        <Container className=''>
            <Field
                label={{ text: intl.formatMessage({ id: 'compliance.requirements.create.requirement_name' }) }}
                value={name}
                onChange={setName}
                onBlur={() => checkValidation('name')}
                errorMessage={errorMessage.name}
            />
            <div>
                <Label text={translate('compliance.requirements.create.requirement_start_date')} />
                <InvalidFieldMessage message={errorMessage.date} />
                <DatePicker3 handleChange={(d) => d ? setDate(format(d, DEFAULT_DATE_FORMAT2)) : null} />

            </div>
            <TextArea
                label={translate('compliance.requirements.create.requirement_description')}
                placeholder={translate('compliance.requirements.create.requirement_description.place_holder')}
                height={null}
                value={description}
                onChange={setDescription}
            />
        </Container>
    </Modal >

    return createModal;
}
export default ComplianceCreateRequirementModal;
