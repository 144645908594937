import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from '../../../base/hooks';
import { byId } from '../../../base/types';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import { ExpirationFromFileTypeEnum, FrequencyTypeEnum, NoExpirationTypeEnum, PerOrderTypeEnum } from '../../../compliance_api/models';

type Props = {
    className?: string;
    selectedValue?: number;
    onValueChanged(value: number): void;
};

const expiryIconsMap: byId<Icons> = {
    [FrequencyTypeEnum.Frequency]: 'expiry_renewal',
    [NoExpirationTypeEnum.NoExpiration]: 'expiry_never',
    [PerOrderTypeEnum.PerOrder]: 'expiry_by-order',
    [ExpirationFromFileTypeEnum.ExpirationFromFile]: 'expiry_time-based',
}


const ComplianceRequirementDocumentSpecificationFrequencySelector = (props: Props): React.ReactElement => {
    const { className } = props;
    const frequencyValues = [
        { key: 12, value: 12, text: useTranslation('compliance.requirements.document_specification.edit_frequency.every', { months: 12 }) },
        { key: 24, value: 24, text: useTranslation('compliance.requirements.document_specification.edit_frequency.every', { months: 24 }) },
        { key: 60, value: 60, text: useTranslation('compliance.requirements.document_specification.edit_frequency.every_year', { year: 5 }) },
    ];
    return <Dropdown options={frequencyValues} selection fluid defaultValue={props.selectedValue} onChange={(e, d) => props.onValueChanged(d.value as number)} />
}
export default ComplianceRequirementDocumentSpecificationFrequencySelector;
