import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Sizes } from '../../../base/components/types';
import { useTranslation } from '../../../base/hooks';
import Button from '../../../base/ui/components/buttons/Button';
import Container from '../../../base/ui/components/containers/Container';
import EmptyState, { EmptyStateType } from '../../../base/ui/components/emptyState/EmptyState';
import Header from '../../../base/ui/components/headers/Header';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getComplianceRequirementTemplate } from '../complianceSlice';
import { getComplianceRequirementSelector } from '../selectors/ComplianceSelectors';
import ComplianceProductState from './ComplianceProductState';
import ComplianceRequirementAddDocumentSpecifictionModal from './ComplianceRequirementAddDocumentSpecifictionModal';
import ComplianceCreateRequirementModal from './ComplianceRequirementCreateModal';
import ComplianceRequirementDetailedTags from './ComplianceRequirementDetailedTags';
import ComplianceRequirementDocumentSpecification from './ComplianceRequirementDocumentSpecification';
import ComplianceRequirementInfo from './ComplianceRequirementInfo';

type Props = {
    className?: string;
    create?: boolean;
    id?: string;
};

const ComplianceRequirementDetail = (props: Props): React.ReactElement => {
    const { className, create, id } = props;
    const location = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const requirementData = useSelector(getComplianceRequirementSelector);

    const [edit, setEdit] = useState(false);
    const [tags, updateTags] = useState(requirementData?.requirement_tags);
    const [showAddDocumentSpecification, setShowAddDocumentSpecification] = useState(false);
    const hasDocumentSpecifications = requirementData?.document_templates?.length > 0;
    useEffect(() => {
        !create && dispatch(getComplianceRequirementTemplate(id));
    }, [id]);
    useEffect(() => {
        requirementData && updateTags(requirementData.requirement_tags);
    }, [requirementData]);

    const addDocumentSpecification = () => {
        setShowAddDocumentSpecification(true);
    }

    const translate = (key: string): string => {
        return intl.formatMessage({ id: key });
    }
    const cancelCreate = () => {
        history.replace('/compliance/requirements')
    }
    const info = requirementData && <ComplianceRequirementInfo edit={edit} requirementData={requirementData} />

    const docs = hasDocumentSpecifications ? requirementData?.document_templates?.map((dt, i) => {
        return <ComplianceRequirementDocumentSpecification
            index={i}
            isLast={i === (requirementData?.document_templates.length - 1)}
            documentSpecification={dt}
            startDate={requirementData.requirement_start_date}
            key={dt.document_template_id}
        />
    })
        :
        <div className='cursor-pointer px-6 pb-4' onClick={() => setEdit(true)}><EmptyState headerClassName='justify-start' type={EmptyStateType.Inline} title={useTranslation('compliance.requirements.no_specifications_yet')} /></div>

    const docSpecsAndTags = <ContentSection
        noPadding={true}
        footer={<div className='pt-2'><Button onPress={addDocumentSpecification} size={Sizes.XSmall} icon='operation_add'><FormattedMessage id='compliance.requirements.add_document_specification.button' /></Button></div>}
        content={
            <Container direction='col' className=''>
                <ComplianceRequirementDetailedTags requirementId={id} tags={tags} onUpdateTags={updateTags} />
                {docs}
            </Container>} />

    const stateSection = <ComplianceProductState
        isDisabled={true}
        productId={''}
        state={'active'}
        showEditIcon={false}
    />;
    const content = <>
        <ComplianceRequirementAddDocumentSpecifictionModal
            open={showAddDocumentSpecification}
            requirementId={requirementData?.requirement_id}
            close={() => setShowAddDocumentSpecification(false)} />
        <PageContainer>
            {/* <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.product' }, { name: title })} /> */}
            <Header
                pageTitle={requirementData?.requirement_name?.text}
                // reference={id}
                entity={translate('page_title.entity.requirement')} />

            <div className='flex flex-col'>
                <div className='flex-col md:flex-row w-full flex md:space-x-4'>
                    <div className='w-full md:w-8/12 order-last md:order-first'>
                        {info}
                        {docSpecsAndTags}
                    </div>
                    <div className='w-full md:w-4/12 order-first md:order-last pt-0 pb-2'>
                        {/* stateSection */}
                    </div>
                </div>
            </div>
        </PageContainer>
    </>

    const createModal = <ComplianceCreateRequirementModal
        cancel={cancelCreate}
    />
    return create ? createModal : content;
}
export default ComplianceRequirementDetail;
