import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Popup } from 'semantic-ui-react';
import Button from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon from '../../../base/ui/components/icons/Icon';
import { Sizes } from '../../../base/ui/components/types';
import { preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { DetailedTag } from '../../../compliance_api/models';
import ComplianceAddAndDeleteTag from './ComplianceAddAndDeleteTag';

type Props = {
    className?: string;
    tag: any;
    tagsId: string;
    disabled?: boolean;
    checked?: boolean;
    isExpanded: boolean;
    selectedTagsOnTag: DetailedTag[];
    subCnt: number;
    level: number;
    selectTag(tag: DetailedTag, checked: boolean, level: number): void;
    addExpandedTag(tagId: string): void;
    onAddTag(catId: string, tagId: string): void;
    updateNewTag(tagId: string): void;
    removeTag(tag: DetailedTag): void;
};

const ComplianceSelectTag = (props: Props): React.ReactElement => {
    const { className, tag, tagsId, disabled, checked, selectTag, selectedTagsOnTag, subCnt, level, isExpanded, addExpandedTag, onAddTag, updateNewTag, removeTag } = props;
    const intl = useIntl();
    const [showNew, setShowNew] = useState(false);
    const hasChildren = tag.children && tag.children.length > 0 || false;

    useEffect(() => {
        //
    }, []);
    return <div key={'subcat_' + tagsId} className='group'>
        <div className={twMerge('justify-between items-center w-full flex py-1 cursor-pointer hover:bg-[#F9FAFB]' /* , addNewTag && 'bg-highlight-light-green' */)}
            key={'key'} onClick={() => hasChildren && addExpandedTag(tagsId)}>
            <div className='flex items-center space-x-1 w-full'>
                {isExpanded ? <Icon name='microinteraction_collapse' /> : <Icon name='microinteraction_expand' className={twMerge(!hasChildren && 'text-transparent')} />}
                <div className='flex space-x-2 items-center w-full' onClick={(e) => {
                    disabled ? null : selectTag(tag, checked, level);
                    preventDefaultAndStopPropagation(e);
                }}>
                    <Checkbox disabled={disabled} checked={checked} />
                    <div className='w-full'>{tag.name}</div>
                    {subCnt > 0 && level === 1 && <Popup trigger={<div className='items-center flex py-0 px-2 bg-attention text-xs font-normal'><FormattedMessage id='compliance.tags_browser.tags_selected_cnt' values={{ cnt: subCnt }} /></div>}>
                        <h4><FormattedMessage id='compliance.tags_browser.selected_tags_on_level' /></h4>
                        {selectedTagsOnTag.map((s) => {
                            return <div key={s.category_id} className='border p-1 m-1'>{s.path.join('>')}</div>
                        })}
                    </Popup>}
                    <div className='invisible group-hover:visible'>
                        <ComplianceAddAndDeleteTag
                            onAddTag={onAddTag}
                            updateNewTag={updateNewTag}
                            show={showNew}
                            onShow={setShowNew} tag={tag} deleteFunction={() => removeTag(tag)} isBase={false} /></div>

                </div>
            </div>


        </div>
        {showNew && <div className='flex w-full flex-col gap-1 py-2 p-1 m-1'>
            <form className='space-y-1'
                onSubmit={(e) => {
                    setShowNew(false);
                    onAddTag(tag.category_id, tag.tag_id);
                }}>
                <Field type='text'
                    className={'w-full'}
                    onChange={(value) => updateNewTag?.(value)}
                    placeholder={intl.formatMessage({ id: 'compliance.tags_browser.enter_new_tag' })}
                    autoFocus
                />
                <div className='flex gap-2'>
                    <Button size={Sizes.XSmall} className='' secondary onPress={() => {
                        setShowNew(false);
                        onAddTag(tag.category_id, tag.tag_id);
                    }}><FormattedMessage id='compliance.category_selector.save_new_tag' /></Button>
                    <Button size={Sizes.XSmall} className='' onPress={() => setShowNew(false)}><FormattedMessage id='globals.cancel' /></Button>
                </div>
            </form>
        </div>}
    </div>
}
export default ComplianceSelectTag;
