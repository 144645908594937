import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Group } from '../../../backend_api/models';
import { AppState, ComponentOrStringType } from '../../../base/types';
import Icon from '../../../base/ui/components/icons/Icon';
import Popup from '../../../base/ui/components/popup/Popup';
import { getUserNameText } from '../../users/components/Widgets';
import { getUsersInGroupSelector } from '../../users/selectors';

type Props = {
    className?: string;
    group: Group;
    trigger?: ComponentOrStringType;
};

const GroupMembersPopup = (props: Props): React.ReactElement => {
    const { className, group, trigger } = props;
    const intl = useIntl();
    const usersInGroup = useSelector((state: AppState) => getUsersInGroupSelector(state, group.id))
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <Popup
        on='hover'
        
        trigger={trigger || group.name}
    >
        <div className='max-w-[500px] flex gap-x-1 pb-2'><Icon className='text-primary' name='user_group' />
            <span className='font-bold whitespace-nowrap truncate'>{group.name}</span><span className='whitespace-nowrap'><FormattedMessage id='groups.group_members' /></span></div>
            {usersInGroup.length === 0 && <div><FormattedMessage id='groups.group_members.no_members'/></div>}
        <div className='space-y-1'>{usersInGroup.map((u) => {
            return <div className=''>
                <div>{getUserNameText(u)}</div>
                <div className='text-secondary text-sm'>{u.email}</div>
            </div>
        })}</div>
    </Popup>
}
export default GroupMembersPopup;
