import React, { useEffect, useState } from 'react';
import { Group } from 'react-aria-components';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';

type Props = {
    className?: string;
    children?: ComponentOrStringType;
    direction?: 'rows' | 'col';
};

const Container = (props: Props): React.ReactElement => {
    const { className, children, direction = 'cols' } = props;
    const [state, setState] = useState();
    const base = '';
    const gap = direction === 'rows' ? 'gap-x-4' : 'gap-y-4 flex-col';
    useEffect(() => {
        //
    }, []);
    return <Group  className={twMerge('flex', base, gap, className)}>
        {children}
    </Group>
}
export default Container;
