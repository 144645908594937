import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { showModal } from '../../../base/baseSlice';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { Sizes } from '../../../base/components/types';
import { useTranslation } from '../../../base/hooks';
import { byId } from '../../../base/types';
import { TextArea, TextAreaHeight } from '../../../base/ui/components/TextArea';
import Button from '../../../base/ui/components/buttons/Button';
import Container from '../../../base/ui/components/containers/Container';
import EmptyState, { EmptyStateType } from '../../../base/ui/components/emptyState/EmptyState';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon from '../../../base/ui/components/icons/Icon';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import Label from '../../../base/ui/components/labels/Label';
import EditAction from '../../../base/ui/components/util/EditAction';
import { showSnackbar } from '../../../base/ui/uiSlice';
import { getPrettyDate, twMerge } from '../../../base/utils';
import { DetailedDocumentTemplate, DocumentTemplateScopeProductTypeEnum, DocumentTemplateScopeSupplierOrProductionUnitTypeEnum, EditDocumentTemplateExpirationRule, ExpirationFromFileTypeEnum, FrequencyTypeEnum, NoExpirationTypeEnum, PerOrderTypeEnum } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { complianceDeleteDocumentSpecification, complianceUpdateDocumentSpecificationDescription, complianceUpdateDocumentSpecificationName } from '../complianceSlice';
import { getComplianceRequirementSelector } from '../selectors/ComplianceSelectors';
import ComplianceDisplayResponsibles from './ComplianceDisplayResponsibles';
import ComplianceRequirementEditDocumentSpecification, { EditViewType } from './ComplianceRequirementEditDocumentSpecification';

type Props = {
    className?: string;
    documentSpecification: DetailedDocumentTemplate;
    startDate: string;
    index: number;
    isLast: boolean;
};

const expiryIconsMap: byId<Icons> = {
    [FrequencyTypeEnum.Frequency]: 'expiry_renewal',
    [NoExpirationTypeEnum.NoExpiration]: 'expiry_never',
    [PerOrderTypeEnum.PerOrder]: 'expiry_by-order',
    [ExpirationFromFileTypeEnum.ExpirationFromFile]: 'expiry_time-based',

}
const scopeIconsMap: byId<Icons> = {
    [DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope]: 'object_product',
    [DocumentTemplateScopeSupplierOrProductionUnitTypeEnum.DocumentTemplateSupplierOrProductionUnitScope]: 'user_supplier',
}
const ComplianceRequirementDocumentSpecification = (props: Props): React.ReactElement => {
    const { className, documentSpecification, isLast, startDate } = props;
    const isFirst = props.index === 0;
    const location = useLocation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const requirementData = useSelector(getComplianceRequirementSelector);
    const [edit, setEdit] = useState(false);
    const [editModal, showEditModal] = useState(false);
    const [name, setName] = useState(documentSpecification?.document_template_name.text);
    const [description, setDescription] = useState(documentSpecification?.document_template_description.text);
    const [view, setView] = useState<EditViewType>(undefined);

    const [showAddDocumentSpecification, setShowAddDocumentSpecification] = useState(false);
    const fakeInputStyle = 'flex rounded-md flex-1 border-input border bg-input text-base px-3 py-2 cursor-pointer';
    const iconClass = 'text-primary';

    const translate = (key: string): string => {
        return intl.formatMessage({ id: key });
    }
    const getExpirationRuleLabel = (rule: EditDocumentTemplateExpirationRule, isOption?: boolean) => {
        let ret;
        if (documentSpecification.expiration_rule.type === 'frequency') {
            const showMonths = documentSpecification.expiration_rule.frequency_value !== 60;
            if (isOption) {
                ret = intl.formatMessage({ id: showMonths ? 'compliance.requirements.document_specification.edit_frequency.every' : 'compliance.requirements.document_specification.edit_frequency.every_year' },
                    showMonths ? { months: documentSpecification.expiration_rule.frequency_value } : { year: documentSpecification.expiration_rule.frequency_value / 12 })
            } else {
                ret = intl.formatMessage({ id: showMonths ? 'compliance.requirements.document_specification.edit_frequency.frequency_renews_every' : 'compliance.requirements.document_specification.edit_frequency.frequency_renews_every_year' },
                    showMonths ? { months: documentSpecification.expiration_rule.frequency_value } : { year: documentSpecification.expiration_rule.frequency_value / 12 })
            }
        } else {
            ret = translate('compliance.requirements.expiration_rule.' + documentSpecification.expiration_rule.type + '.text');
        }
        return isOption ? ret : <div className='flex gap-x-1'><Icon className={iconClass} name={expiryIconsMap[rule.type]} />{ret}</div>

    }
    const getScope = (scope) => {
        return <div className='flex gap-x-1'><Icon className={iconClass} name={scopeIconsMap[scope.type]} />{translate('compliance.requirements.document_template_scope.' + documentSpecification.scope.type)}</div>
    }
    const getApprovalResponsibles = () => {
        return null;
    }
    const doEdit = (type: EditViewType) => {
        setView(type);
        dispatch(showModal({ id: 'editDocumentSpecification_' + documentSpecification.document_template_id, show: true, data: { type, documentSpecification } }));
    }
    const updateName = (name: string) => {
        dispatch(complianceUpdateDocumentSpecificationName({ name, documentSpecificationId: documentSpecification.document_template_id, requirementId: documentSpecification.requirement_id }));
    }
    const updateDescription = (name: string) => {
        dispatch(complianceUpdateDocumentSpecificationDescription({ description, documentSpecificationId: documentSpecification.document_template_id, requirementId: documentSpecification.requirement_id }));
    }
    const deleteDocumentSpecification = () => {
        dispatch(complianceDeleteDocumentSpecification({ requirementId: documentSpecification.requirement_id, documentSpecificationId: documentSpecification.document_template_id })).then(() => {
            dispatch(showSnackbar({
                hideAfter: 10000,
                content: <span className='flex gap-x-1'>
                    <FormattedMessage id='compliance.requirements.edit.delete_document_specification.document_specification_was_deleted' values={{ documentSpecificationName: documentSpecification.document_template_name.text, b: (chunks) => <strong>{chunks}</strong> }} />
                </span>,
            }));
        })

    }
    const displayUploadResponsibles = <ComplianceDisplayResponsibles
        groups={documentSpecification.upload_responsible.groups}
        users={documentSpecification.upload_responsible.users}
        productResponsiblesIsResponsible={documentSpecification.upload_responsible.product_responsibles}
        supplierIsResponsible={documentSpecification.upload_responsible.supplier}
        returnAsString={true}
    />
    const displayApprovalResponsibles = <ComplianceDisplayResponsibles
        groups={documentSpecification.approve_responsible.groups}
        users={documentSpecification.approve_responsible.users}
        productResponsiblesIsResponsible={documentSpecification.approve_responsible.product_responsibles}
        supplierIsResponsible={documentSpecification.approve_responsible.supplier}
        returnAsString={true}
    />
    return <><Container className={twMerge(' px-4 gap-y-2 w-full flex', !isLast && 'border-b')} direction='col'>
        <div className='gap-y-2 flex w-full items-center justify-between'>
            <KeyValuePair
                keyItem={{ name: isFirst ? 'Document specifications' : null }}
                value={{ value: <div className='font-bold whitespace-pre'>{name}</div> }} />
            <EditAction edit={edit} onEditUpdated={setEdit} />
        </div>
        <Container direction='rows' className='pb-4'>
            {!edit && <><div className='w-7/12 space-y-4'>
                <KeyValuePair
                    keyItem={{ name: translate('compliance.requirements.document_specification.description') }}
                    value={{ value: <div className='break-all'>{description ? description : <EmptyState type={EmptyStateType.Inline} headerClassName='justify-start' title={translate('compliance.requirements.document_specification.no_description')} />}</div> }} />
            </div>
                <div className='w-5/12 space-y-4'>
                    <KeyValuePair
                        keyItem={{ name: translate('compliance.requirements.document_specification.scope') }}
                        value={{ value: getScope(documentSpecification.scope) }} />
                    <KeyValuePair
                        keyItem={{ name: translate('compliance.requirements.document_specification.expiry_rule') }}
                        value={{ value: getExpirationRuleLabel(documentSpecification.expiration_rule) }} />
                    <KeyValuePair
                        keyItem={{ name: translate('compliance.requirements.document_specification.responsible_for_adding_files') }}
                        value={{
                            value: displayUploadResponsibles,
                        }} />
                    <KeyValuePair
                        keyItem={{ name: translate('compliance.requirements.document_specification.responsible_for_approval') }}
                        value={{
                            value: displayApprovalResponsibles,
                        }} />
                </div>
            </>}
            {edit && <Container className='w-7/12'>
                <Field
                    label={{ text: intl.formatMessage({ id: 'compliance.requirements.document_specification.document_name' }) }}
                    value={name}
                    onChange={setName}
                    onBlur={() => updateName(name)}
                // errorMessage={errorMessage.name}
                />
                <TextArea
                    label={translate('compliance.requirements.document_specification.document_description')}
                    height={TextAreaHeight.DEFAULT_AUTO_EXPAND}
                    value={description}
                    onChange={setDescription}
                    onBlur={() => updateDescription(description)}
                />
                {/* <Field
                    inlineAction={{ name: 'operation_edit' }}
                    label={{ text: intl.formatMessage({ id: 'compliance.requirements.document_specification.document_scope' }) }}
                    value={translate('compliance.requirements.document_template_scope.' + documentSpecification.scope.type)}
                    onChange={() => null}
                    isReadOnly
                /> */}

                {documentSpecification.expiration_rule.type === FrequencyTypeEnum.Frequency && <div>
                    <Label text='Renewal frequency' />
                    <div className={twMerge(fakeInputStyle, 'justify-between')} onClick={() => doEdit('expiry_rule')}>
                        {getExpirationRuleLabel(documentSpecification.expiration_rule, true)}
                        <Icon className={iconClass} name='operation_edit' />
                    </div>
                </div>}
                <div>
                    <Label text='Responsible for adding files' />
                    <div className={twMerge(fakeInputStyle, 'justify-between')} onClick={() => doEdit('upload_responsible')}>
                        {displayUploadResponsibles}
                        <Icon className={iconClass} name='operation_edit' />
                    </div>
                </div>
                <div>
                    <Label text='Responsible for approval' />
                    <div className={twMerge(fakeInputStyle, 'justify-between')} onClick={() => doEdit('approval_responsible')}>
                        {/* documentSpecification.approve_responsible.supplier && 'Supplier'}
                        {documentSpecification.approve_responsible.product_responsibles && 'Product responsible' */}
                        {displayApprovalResponsibles}
                        <Icon className={iconClass} name='operation_edit' />
                    </div>
                </div>
                <div className='flex justify-between'>
                    <Button className='' primary size={Sizes.Small} onPress={() => {
                        setEdit(false);
                    }} ><FormattedMessage id='globals.done_editing' /></Button>
                    <DeleteConfirm
                        type='alert'
                        alertHeader={useTranslation('compliance.requirements.edit.delete_document_specification.header')}
                        alertText={intl.formatMessage({ id: 'compliance.requirements.edit.delete_document_specification.text' }, { date: getPrettyDate(startDate), b: (chunks) => <strong>{chunks}</strong> })}
                        alertButtonText={useTranslation('compliance.requirements.edit.delete_document_specification.action_ok')}
                        deleteFunction={() => deleteDocumentSpecification()}
                        trigger={<span>
                            <Button size={Sizes.Small} stopPropagation={true} icon='operation_delete'>
                                <FormattedMessage id='compliance.requirements.edit.delete_document_specification.action' /></Button>
                        </span>} />
                </div>
            </Container>}

        </Container>
    </Container>
        <ComplianceRequirementEditDocumentSpecification documentSpecification={documentSpecification} viewType={view} />
    </>
}
export default ComplianceRequirementDocumentSpecification;
