import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import CheckboxRadio from '../../../base/components/basic/CheckboxRadio';
import Button, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { getStringsSeparatedBy, isUndefinedOrNull, twMerge } from '../../../base/utils';
import { ResponsibleRequest, SharedDocumentTemplateResponsible } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { getUserNameText } from '../../users/components/Widgets';
import { getGroupsById, getUsersById } from '../../users/selectors';
import ComplianceResponsiblesSelector from './ComplianceResponsiblesSelector';
// import { complianceRequirementSetApproveResponsible } from '../complianceSlice';

type Props = {
    className?: string;
    requirementId: string;
    documentTemplateId?: string;
    responsibleData?: SharedDocumentTemplateResponsible;
    onUpdated?(reponsible: SharedDocumentTemplateResponsible): void;
    onSave?(reponsible: ResponsibleRequest): void;
};

const ComplianceRequirementDocumentResponsible = (props: Props): React.ReactElement => {
    const { className, requirementId, documentTemplateId, responsibleData, onUpdated, onSave } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const usersById = useSelector(getUsersById);
    const groupsById = useSelector(getGroupsById);
    const intl = useIntl();
    const showModal = false;
    const supplierLabel = intl.formatMessage({ id: 'compliance.responsibles.add_responsible.supplier' });
    const prLabel = intl.formatMessage({ id: 'compliance.responsibles.add_responsible.product_responsible' });
    const usersGroupsLabel = 'Users and groups';

    // const [approvalResonsible, setApprovalResonsible] = useState<SetApproveResponsible>({ document_template_id: documentTemplateId, ... });
    const [responsible, setResponsible] = useState(responsibleData);
    const [showUsersAndGroupsSelector, setShowUsersAndGroupsSelector] = useState(responsible?.users?.length > 0 || responsible?.groups?.length > 0);
    const [approvalResonsible, setApprovalResonsible] = useState(responsibleData || {
        group_ids: [],
        user_ids: [],
        product_responsibles: false,
        supplier: false,
    });
    const prev = usePrevious(approvalResonsible)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }

    const trigger = () => {
        const ret = [<div>foo</div>];
        if (responsible?.product_responsibles) {
            ret.push(<div>{prLabel}</div>)
        }
        if (responsible?.supplier) {
            ret.push(<div>{supplierLabel}</div>)
        }
        if (responsible?.users?.length > 0) {
            ret.push(<div className='text-left'>{getStringsSeparatedBy(responsible?.users?.map((user) => getUserNameText(user)), ',', 'and')}</div>);
        }
        if (responsible?.groups?.length > 0) {
            ret.push(<div className='text-left'>{getStringsSeparatedBy(responsible.groups.map((g) => g.name), ',', 'and')}</div>);
        }
        return ret.length === 0 ? [<Button ghost className='p-0'><FormattedMessage id='compliance.responsibles.add_responsible' /></Button>] : ret;
    }
    const save = () => {
        // onSave?.(approvalResonsible);
        // dispatch(complianceRequirementSetApproveResponsible({ ...approvalResonsible, requirement_id: requirementId }))
    }
    useEffect(() => {
        if (!isUndefinedOrNull(prev)) {
            console.log('responsible effect ', responsible)
            onUpdated?.((responsible));
        }
    }, [responsible]);

    const content = <div className={twMerge('flex flex-col gap-y-4', className)}>
        <CheckboxRadio.Checkbox checked={responsible?.supplier} label={supplierLabel} onChange={() => {
            setResponsible({
                ...responsible,
                supplier: !responsible?.supplier,
            })
        }} />
        <CheckboxRadio.Checkbox checked={responsible?.product_responsibles} label={prLabel} onChange={() => {
            setResponsible({
                ...responsible,
                product_responsibles: !responsible?.product_responsibles,
            })
        }} />
        <CheckboxRadio.Checkbox
            checked={showUsersAndGroupsSelector}
            label={usersGroupsLabel}
            onChange={() => {
                const apr = {
                    ...responsible,
                    users: [],
                }
                setResponsible(apr)
                setShowUsersAndGroupsSelector(!showUsersAndGroupsSelector);
            }} />

        {showUsersAndGroupsSelector && <ComplianceResponsiblesSelector
            name={intl.formatMessage({ id: 'compliance.responsibles.add_approve_responsible.users_and_groups' })}
            productId=''
            edit={true}
            responsibleGroups={responsible.groups?.map((g: any) => g.id || g.group_id)}
            responsibleUsers={responsible.users?.map((u) => u.id)}
            onGroupsUpdated={(groupIds) => {
                const apr = {
                    ...responsible,
                    group_ids: groupIds,
                    groups: groupIds.map((g) => {
                        return groupsById[g] as any;
                    }),
                }
                setResponsible(apr);
            }}
            onUsersUpdated={(userIds) => {
                const apr = {
                    ...responsible,
                    users: userIds.map((id) => usersById[id]),
                }
                setResponsible(apr);
            }}
        />}
        {/* <Button onPress={save}>Save</Button> */}

    </div>;
    const modal = <QModal
        actionItems={[{ buttonType: ButtonTypes.Primary, type: ActionTypes.Action, event: save, text: 'xDone' }]}
        width={500}
        trigger={<div className='flex flex-col items-start gap-y-2'>{trigger()}</div>}
        header={intl.formatMessage({ id: 'compliance.responsibles.add_approve_responsible.header' })}
        content={content}>
    </QModal>
    return showModal ? modal : content;
}
export default ComplianceRequirementDocumentResponsible;
