import React, { useEffect, useState } from 'react';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

type Props = {
    className?: string;
    message: ComponentOrStringType;
    header?: string;
    icon?: Icons;
};

const InfoBox = (props: Props): React.ReactElement => {
    const { className, message, header, icon } = props;
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div className={twMerge('p-3 border border-input rounded-md', className)}>
        {header && <div className='mb-1 flex text-secondary text-sm gap-1 font-bold items-center uppercase'><Icon className='text-secondary' name='callout_info' />{header}</div>}
        <div className='text-sm'>{message}</div>
    </div>
}
export default InfoBox;
