import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { SlideImage, SlideVideo } from 'yet-another-react-lightbox';
import { AppState, AppThunk, BaseState, CurrentContext, SiteType } from './types';

const initialState: BaseState = {
    networkBusy: false,
    applicationReady: false,
    errors: undefined,
    layout: { reportApprovalExpanded: false, bodyFixed: false, mobileMenuOpen: false, dontScrollToTopAfterAction: false },
    showModal: { ['']: { show: false } },
    showLightBox: { show: false, index: 0, url: '' },
    showSingleMediaLightBox: { show: false, file: undefined },
    showFilters: false,
    loading: { undefined: false },
    siteType: 'quality',
    currentContext: null,
    contextData: { ['']: undefined }
};
export type LayoutAttributeTypes = 'reportApprovalExpanded' | 'bodyFixed' | 'mobileMenuOpen' | 'dontScrollToTopAfterAction';

export type LayoutAttributes = {
    reportApprovalExpanded: boolean;
    bodyFixed: boolean;
    mobileMenuOpen: boolean;
    dontScrollToTopAfterAction: boolean;
};

export const setLayoutAttribute = (name: LayoutAttributeTypes, value: any): AppThunk => async (dispatch): Promise<void> => {
    dispatch(_setLayoutAttribute({ name, value }));
}
export const sendError = (type: string, error: any, status?: number): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(errorRequest({ type, error, status }));
    };
};

export const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        applicationReady: (state, action: PayloadAction<boolean>) => { state.applicationReady = action.payload },
        applicationNotReady: (state) => { state.applicationReady = false },
        setLoadingById: (state, action: PayloadAction<{ id: string, loading: boolean }>): void => {
            state.loading[action.payload.id] = action.payload.loading;
        },
        _setLayoutAttribute: (state, { payload }: PayloadAction<{ name: LayoutAttributeTypes, value: any }>) => { state.layout[payload.name as string] = payload.value },
        showModal: (state, { payload }: PayloadAction<{ id: string, show: boolean, content?: any, data?: any }>) => {
            state.showModal[payload.id] = { show: payload.show, data: payload.data, content: payload.content, id: payload.id }
        },

        showLightBox: (state, { payload }: PayloadAction<{ show: boolean, index?: number, url?: string }>) => { state.showLightBox = { show: payload.show, url: payload.url, index: payload.index } },
        showSingleMediaLightBox: (state, { payload }: PayloadAction<{ show: boolean, file: SlideImage | SlideVideo }>) => { state.showSingleMediaLightBox = { show: payload.show, file: payload.file } },
        networkBusy: (state, action: PayloadAction<boolean>): void => { state.networkBusy = action.payload },
        clearError: (state, action: PayloadAction<string>): void => {
            const errors = state.errors;
            if (errors && errors[action.payload]) {
                delete errors[action.payload];
            }
        },
        errorRequest: (state, action: PayloadAction<{ type: string, error: any, status: number }>): void => {
            state.errors = { [action.payload.type]: action.payload }
        },
        setSiteType: (state, action: { payload: SiteType }) => { state.siteType = action.payload },
        setContext: (state, action: { payload: CurrentContext }) => {
            state.currentContext = action.payload;
        },
        setContextData: (state, action: { payload: CurrentContext }) => {
            state.contextData[action.payload.context] = action.payload;
        },
        showFilters: (state, action: { payload: boolean }) => {
            state.showFilters = action.payload;
        }

    },
    extraReducers: builder => {
        // Reducer responding to createAsyncThunk's:
    }
});

export const getShowModalSelector = createSelector(
    [(state: AppState) => state.app.BASE.showModal],
    (show) => show,
);

export const getShowLightBoxSelector = createSelector(
    [(state: AppState) => state.app.BASE.showLightBox],
    (show) => show,
);

export const getShowSingleMediaLightBoxSelector = createSelector(
    [(state: AppState) => state.app.BASE.showSingleMediaLightBox],
    (show) => show,
);


export const getSiteTypeSelector = createSelector(
    [(state: AppState) => state.app.BASE.siteType],
    (siteType) => siteType,
);

export const getLoadingByIdSelector = createSelector(
    [(state: AppState, id: string) => state.app.BASE.loading[id]],
    (loading) => loading,
);

export const getCurrentContextSelector = createSelector(
    [(state: AppState) => state.app.BASE.currentContext],
    (context) => context,
);
export const getContextDataSelector = createSelector(
    [(state: AppState) => state.app.BASE.contextData],
    (context) => context,
);
export const getShowFiltersSelector = createSelector(
    [(state: AppState) => state.app.BASE.showFilters],
    (showFilters) => showFilters,
);

const { actions } = baseSlice;
export const { applicationReady, applicationNotReady, _setLayoutAttribute, networkBusy, clearError, errorRequest, showModal, setLoadingById, setSiteType, setContext, setContextData, showFilters, showLightBox, showSingleMediaLightBox } = actions;
export default baseSlice.reducer;
