import React from 'react';
import { useTranslation } from '../../../hooks';
import { isUndefinedOrNull, twMerge } from '../../../utils';
import ContextMenu from '../contextMenu/ContextMenu';

type Props = {
    className?: string;
    beforeEditLabel?: string;
    edit?: boolean;
    onEditUpdated(edit: boolean): void;
    children?: React.ReactNode;
};

const EditAction = (props: Props): React.ReactElement => {
    const { className, edit, onEditUpdated, beforeEditLabel } = props;

    return <div className={twMerge('', className)}>
        {!edit ? <ContextMenu actions={[{
            icon: 'operation_edit',
            label: beforeEditLabel ? beforeEditLabel : 'open edit context',
            labelLeft: !isUndefinedOrNull(beforeEditLabel),
            onPress: () => onEditUpdated(true),
        }]} /> : <ContextMenu actions={[{
            labelLeft: true,
            label: useTranslation('globals.done'),
            onPress: () => onEditUpdated(false),
        }]} />}
    </div>
}
export default EditAction;
