import { format } from 'date-fns';
import { Location } from 'history';
import moment from 'moment';
import qs from 'query-string';
import React, { useEffect, useRef } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { extendTailwindMerge } from 'tailwind-merge';
import { SlideImage, SlideVideo } from 'yet-another-react-lightbox';
import { FileResource, FileResourceTypeEnum, TextWithTranslation } from '../backend_api/models';
import { BasicUser } from '../backend_api/models/BasicUser';
import { globalStore } from '../containers/Root';
import { Filter } from '../modules/filters/types';
import { Locale } from '../modules/globals/model';
import { currentLocaleSelector } from '../modules/globals/selectors';
import { MultiLanguageText } from '../modules/globals/types';
import { ConclusionTypes } from '../modules/inspections/types';
import { BREAKPOINTS, DEFAULT_DATE_FORMAT2, MOBILE_BREAKPOINT, MOBILE_SMALL_BREAKPOINT, TABLET_BREAKPOINT } from './config';
import { AppState, byId } from './types';


export const getMoment = (input?: moment.MomentInput, strict?: boolean, notUTC?: boolean): moment.Moment => {
    return notUTC ? moment(input, strict) : moment.utc(input, strict);
};

moment.updateLocale(moment.locale(), { invalidDate: '' });

export const getPrettyDate = (timestamp: string | Date, locale?: string): string => {
    if (timestamp) {
        // TODO: get formats for locales;
        const format = 'MMM DD, YYYY';
        moment.locale(locale);
        return getMoment(timestamp).format(format);
    }
    else {
        return undefined;
    }
};
export const getPrettyCustomFormat = (timestamp: string | Date, format: string, locale?: string): string => {
    // TODO: get formats for locales;
    moment.locale(locale);
    return getMoment(timestamp).format(format);
};

export const getNowDate = (locale?: string): string => {
    // TODO: get formats for locales;
    moment.locale(locale);
    const format = 'MMM DD, YYYY';
    return getMoment().format(format);
};

export const convertDateToDefaultDateFormatString = (date: Date) => {
    return date && date.toISOString().slice(0, 10);
}

export const isDateNull = (date: Date) => {
    if (date instanceof Date) {
        return date ? date.getTime() === 0 : true;
    }
    return isUndefinedOrNull(date);
}

export const getPrettyTime = (timestamp: string | Date, locale?: string): string => {
    // TODO: get formats for locales;
    moment.locale(locale);
    const format = 'HH:mm';
    return getMoment(timestamp).format(format);
};

export const getPrettyDatetime = (timestamp: string | Date, locale?: string, nonUTC = true): string => {
    // TODO: get formats for locales;
    const format = 'MMM DD, YYYY HH:mm';
    moment.locale(locale);
    return getMoment(timestamp, false, nonUTC).format(format);
};

// Get this week's (+/- offset) number
export const getWeekNumber = (offset = 0): number => {
    return getMoment().add(offset, 'w').isoWeek();
};

export const generateID = (length: number): string => {

    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

export const getOrdinal = (n: number): string => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const localizeOrdinal = (n: number, locale = 'en'): string => {
    const format = new Intl.PluralRules(locale, {
        type: 'ordinal'
    });
    const messages = {
        en: {
            one: 'st',
            two: 'nd',
            few: 'rd',
            other: 'th'
        },
        vi: {
            one: '',
            two: '',
            few: '',
            other: ''
        },
        zh: {
            one: '',
            two: '',
            few: '',
            other: ''
        },
        de: {
            one: '',
            two: '',
            few: '',
            other: ''
        },
        pl: {
            one: '',
            two: '',
            few: '',
            other: ''
        },
        uk: {
            one: '',
            two: '',
            few: '',
            other: ''
        }
    }
    return `${n}${messages[locale][format.select(n)]}`;
};

export const URLToJSON = (url: string): string => {
    const search = url.substring(1); // remove ?
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
};

export const objectsAreIdentical = (obj1: any, obj2: any): boolean => {
    return Object.is(JSON.stringify(obj1), JSON.stringify(obj2));
};

export const compareObjects = (obj1: any, obj2: any): boolean => {
    return Object.is(JSON.stringify(obj1), JSON.stringify(obj2));
};

export const objectsEqual = (obj1: any, obj2: any): boolean => {
    return compareObjects(obj1, obj2);
};

export const objectIsEmpty = (obj: Record<string, unknown>) => {
    return Object.keys(obj).length === 0;
}

// export const deepCopy = <T>(obj: T): T => {
export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export const removeArrayItem = (arr: any[], element: any): any[] => {
    return arr.filter((e) => e !== element);
};
export const removeArrayFromArray = (arr: any[], toRemove: any[]): any[] => {
    return arr.filter((el) => !toRemove.includes(el));
};

export const addArrayItemOrRemoveIfExists = (arr: any[], element: any): any[] => {
    if (arr.includes(element)) {
        return removeArrayItem(arr, element);
    }
    arr.push(element);
    return arr;
};


export const moveArrayItem = (array: any[], from: number, to: number): any[] => {
    const element: any = array[from];
    const newArray: any[] = [...array];
    newArray.splice(from, 1);
    newArray.splice(to, 0, element);
    return newArray;
}

export const fitText = (text: string, noOfLines = 1): string => {
    if (text) {
        const clipAt = 24 * noOfLines;
        if (text.length > clipAt - 3) {
            return text.substr(0, clipAt - 3) + '...';
        }
    }
    return text;
};

export const prettyClipText = (text: string, cutAt: number): string => {
    if (text) {
        if (text.length + 5 > cutAt) {
            return text.substr(0, cutAt) + '...';
        }
    }
    return text;
};

export const objectHasKeys = (obj: any): boolean => {
    if (obj) {
        return Object.keys(obj).length > 0;
    }
    return false;
};

export const getWeekDay = (date: string): number => {
    const day = getMoment(date).day();
    if (day === 0) {
        return 7;
    }
    return day;
};

export const sortArrayByKey = (arr: any[], key: string): any[] => {
    return arr.sort((a, b) => {
        if (a[key] > b[key]) {
            return 1;
        }
        if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    });
};
export const sortArrayByKeys = (arr: any): any[] => {
    return arr.sort((a: any, b: any) => {
        let ret;
        if (a.inspection_type && b.inspection_type) {
            const aVal = a.inspection_type.sequence;
            const bVal = b.inspection_type.sequence;
            ret = aVal - bVal;
        } else {
            ret = 0;
        }
        return ret;
    });
};

export const arrayOfWeeksInYear = (centerCurrentWeek: boolean): number[] => {
    const wy = getMoment().isoWeeksInYear() * 2;
    const wn = Math.ceil(centerCurrentWeek ? getMoment().week() - wy / 2 : getMoment().isoWeek());
    const wty = wy - wn;
    const wny = wy - wty;
    const arr = Array.from({ length: wty + 1 }, (v, k) => k + wn).concat(
        Array.from({ length: wny - 1 }, (v, k) => k + wy + 1));
    return arr;
};

export const getWeekNumberByDate = (date: string): number => {
    return getMoment(date).isoWeek();
};

export const getLocaleString = (locale: Locale): string => {
    return locale.language + '-' + locale.country;
};

export const getLocaleLanguage = (locale: Locale | string): string => {
    return getLocaleLanguageString(locale);
};

export const getLocaleLanguageString = (locale: Locale | string): string => {
    if (typeof (locale) === 'string') {
        return locale.split('-')[0];
    }
    return locale.language;
};

export const getLocaleSpecificString = (langString: MultiLanguageText): string => {
    const state = globalStore.getState();
    const locale = currentLocaleSelector(state as unknown as AppState);
    const lang = getLocaleLanguageString(locale);
    return langString[lang] || langString.C;
}

export const groupArrayByObjectKey = (arr: any, key: string, returnAsArray = false): byId<[]> => {
    let ret;
    if (returnAsArray) {
        ret = arr.reduce(
            (obj, item) => Object.assign(obj, (obj[item[key]] || []).concat(item)), {},
        );
    }
    ret = arr.reduce(
        (obj, item) => Object.assign(obj, { [item[key]]: (obj[item[key]] || []).concat(item) }),
        {},
    );
    return ret;
};

export const copyToClipboard = (str: string): void => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)     // Store selection if found
            : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
    }
};

export const getMapLocation = (location: { latitude?: number; longitude?: number }): [number, number] => {
    if (location) {
        const minLong = -180;
        const maxLong = 180;
        const longVal = location.longitude || 0;
        const longitude = longVal < minLong ? minLong : (longVal > maxLong ? maxLong : longVal);
        const minLat = -90;
        const maxLat = 90;
        const latVal = location.latitude || 0;
        const latitude = latVal < minLat ? minLat : (latVal > maxLat ? maxLat : latVal);
        return ([latitude, longitude]);
    }
    return ([0, 0]);
};

export const toFixed = (n: number): number => {
    return Math.floor(n * 100) / 100;
};

// export const getArrayInChunks = <T>(array: Array<T>, perChunk: number): any[] => {
export const getArrayInChunks = (array: any[], perChunk: number): any[] => {
    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
};

export const replaceAll = (str, term, replacement): string => {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
};

function escapeRegExp(string2) {
    return string2.replace(/[.*+?^${ }()|[\]\\]/g, '\\$&');
}

export const capitaliseWord = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const nameFunction = (name, body) => {
    return { [name](...args) { return body(...args); } }[name];
};

export const preventDefaultAndStopPropagation = (e): void => {
    if (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
    }
};

export const stopPropagation = (e): void => {
    if (e) {
        e.stopPropagation();
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
    }
};

export const useIsTabletDevice = (): boolean => {
    return useMediaQuery({ maxWidth: TABLET_BREAKPOINT });
};
export const useIsMobileDevice = (): boolean => {
    return useMediaQuery({ maxWidth: MOBILE_BREAKPOINT });
};
export const useIsSmallMobileDevice = (): boolean => {
    return useMediaQuery({ maxWidth: MOBILE_SMALL_BREAKPOINT });
};

export const useIsMobileDevicePortrait = (): boolean => {
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    return useMediaQuery({ maxWidth: MOBILE_BREAKPOINT }) && isPortrait;
};
export const useIsMobileDeviceLandscape = (): boolean => {
    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    return useMediaQuery({ maxWidth: MOBILE_BREAKPOINT }) && !isPortrait;
};

export const useMaxWidthBreak = (bp: BREAKPOINTS): boolean => {
    return useMediaQuery({ maxWidth: bp - 1 });
}
export const useMinWidthBreak = (bp: BREAKPOINTS): boolean => {
    return useMediaQuery({ minWidth: bp });
}

export const getLanguageString = (text: MultiLanguageText, lang: string): string => {
    if (text[lang]) {
        return text[lang];
    }
    else {
        return text.C;
    }
}

export const getObjectByIdCount = (obj: any): number => {
    let cnt = 0;
    Object.keys(obj).forEach((arr) => {
        cnt += (obj[arr].length);
    });
    return cnt;
}

export const setViewportSize = (type: 'mobile' | 'default'): void => {
    const viewport_meta = document.getElementById('viewport-meta');
    const viewports = {
        mobile: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0',
        // default: 'width=device-width'
        default: 'width=1024'
    };
    const currentContent = viewport_meta.getAttribute('content');
    // Change the viewport value based on screen.width
    if (type == 'default' && currentContent !== viewports.default) {
        viewport_meta.setAttribute('content', viewports.default);
    }
    if (type == 'mobile' && currentContent !== viewports.mobile) {
        viewport_meta.setAttribute('content', viewports.mobile);
    }
}

export const usePrevious = (value: any): React.MutableRefObject<any> => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const toDegreesMinutesAndSeconds = (coordinate: number): string => {
    const CHAR_DEG = '\u00B0';
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    return degrees + CHAR_DEG + ' ' + minutes + "' " + seconds + '"';
};

export const convertDMS = (lat: number, lng: number): string => {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = Math.sign(lat) >= 0 ? 'N' : 'S';
    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = Math.sign(lng) >= 0 ? 'E' : 'W';
    return latitude + ' ' + latitudeCardinal + ' x ' + longitude + ' ' + longitudeCardinal;
};


export const useFormatMessage = (messageId: string): string => {
    return useIntl().formatMessage({ id: messageId });
};
export const useIntl2 = (): IntlShape => {
    return useIntl();
};


export const urlParamsToArray = (urlParams: string): Filter => {
    const o = qs.parse(urlParams);
    const o1 = {};
    Object.keys(o).forEach((k) => {
        if (!Array.isArray(o[k])) {
            o1[k] = [o[k]];
        } else {
            o1[k] = o[k];
        }
    });
    return o1;
};

export const addToUrlParams = (addParams: { key: string; value: string | string[] }[], currentParams: string): string => {
    const ps = urlParamsToArray(currentParams);
    addParams.forEach((param) => {
        if (typeof param.value === 'string') {
            ps[param.key] = [param.value];
        } else {
            ps[param.key] = param.value;
        }
    });
    return qs.stringify(ps);
};

export const getLocationEntries = (location: Location): Filter => {
    return urlParamsToArray(location.search);
};
export const getLocationEntry = (location: Location, entryName: string): string[] => {
    return urlParamsToArray(location.search)[entryName] || [];
};

export const escapeHTML = (h: string): string => {
    const html = h.toString();
    const r = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&apos;/g, "'");
    return r;
};

export const convertConclusionCategoy = (conclusionCategory: ConclusionTypes): string => {
    if (conclusionCategory === 'not_set') {
        return 'pending';
    }
    else {
        return conclusionCategory;
    }
};

export const filterDisabledUsersById = (usersById: byId<BasicUser>): byId<BasicUser> => {
    const ret = {};
    Object.keys(usersById).forEach((key) => {
        const user = usersById[key];
        if (!user.disabled) {
            ret[key] = user;
        }
    });
    return ret;
};

export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isStringAnUrl = (text: string): boolean => {
    if (text.startsWith('http')) {
        try {
            new URL(text);
        }
        catch (_) {
            return false;
        }
        return true;
    }
    else {
        return false
    }
}

export function updateLocaleSpecificString(multiLang: MultiLanguageText, update: string, language?: string): MultiLanguageText
export function updateLocaleSpecificString(multiLang: TextWithTranslation, update: string, language?: string): TextWithTranslation
export function updateLocaleSpecificString(multiLang: TextWithTranslation | MultiLanguageText, update: string, language?: string): TextWithTranslation | MultiLanguageText {

    const usedLanguage = language || getCurrentLanguage();

    if (isTextWithTranslation(multiLang)) {
        const copy = deepCopy(multiLang);
        // Important: update must match  'getLocaleSpecificString()'
        if (copy.language === usedLanguage || !copy.translations[usedLanguage]) {
            // TODO I have no idea if this is how we want to update 'language' and 'text'
            copy.text = update;
            copy.language = usedLanguage
        } else {
            copy.translations[usedLanguage] = update
        }

        return copy
    } else {
        const copy = deepCopy(multiLang);
        // Important: update must match  'getLocaleSpecificString()'
        if (copy[usedLanguage]) {
            copy[usedLanguage] = update;
        } else {
            copy.C = update;
        }
        return copy
    }
}

export function getCurrentLanguage() {
    const state = globalStore.getState();
    const locale = currentLocaleSelector(state as unknown as AppState);
    return getLocaleLanguageString(locale);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function isTextWithTranslation(t: Object): t is TextWithTranslation {
    // eslint-disable-next-line no-prototype-builtins
    return t.hasOwnProperty('language') && t.hasOwnProperty('text') && t.hasOwnProperty('language')
}

export function useClickOutsideTrigger(ref, callback: () => void) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                setTimeout(callback, 200);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}
export const isUndefinedOrNull = (value): boolean => {
    return value === undefined || value === null;
}

export const isUndefinedOrNullOrEmptyString = (value: any): boolean => {
    return value === undefined || value === null || value === '';
}

export const containsLongAndUnbrokenString = (str: string, len: number) => {
    return str && str.split(' ').filter((s) => s.length > len).length > 0;
}

export const mixedStringToNumber = (s: string): number => {
    const numberArray = s.split('').filter((item) => !isNaN(parseInt(item, 10)));
    if (numberArray.length > 0) {
        return numberArray.map((n) => n).reduce((a, b) => a + b) as unknown as number;
    }
    return null;
};
export const mixedStringToNumberArray = (s: string): number[] => {
    return s && s.split('').filter((item) => !isNaN(parseInt(item, 10))) as unknown as number[];
};

export const mixedStringToNumberString = (s: string): string => {
    const numberArray = s.split('').filter((item) => !isNaN(parseInt(item, 10)));
    if (numberArray.length > 0) {
        return numberArray.map((n) => n).reduce((a, b) => a + b);
    }
    return null;
};

export const getMaxNumber = (n: number, max: number) => {
    return Math.min(Math.max(n, 1), max);
}

export const twMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            'leading': [
                'rem-xs', 'rem-sm', 'rem-base', 'rem-lg', 'rem-xl', 'rem-2xl', 'rem-3xl, rem-none'
            ],
            'font-size': [{
                text: ['xss', 'xxs', 'smx', 'sml', 'xsl', 'menu-item'],
            }],
        }
    }
});


export const getDateAsTimestamp = (date: Date): string => {
    return format(date, DEFAULT_DATE_FORMAT2);
}

export const arrayDiff = (current: any[], updated: any[]) => {
    return {
        added: updated.filter((v) => !current.includes(v)),
        removed: current.filter((v) => !updated.includes(v))
    };
}

/* For large lists. For small lists, arrayDiff works great. */
export function arrayDiffLarge(previous: string[], next: string[]) {
    const previousKeys = {};
    previous.forEach(key => previousKeys[key] = true);

    const nextKeys = {}
    next.forEach(key => nextKeys[key] = true);

    const addedKeys = [];
    const removedKeys = [];

    previous.forEach(currentKey => {
        /* current key is not included in the new data set */
        if (!nextKeys[currentKey]) {
            removedKeys.push(currentKey)
        }
    });

    next.forEach(updatedKey => {
        /* updated key was not in the previous dataset */
        if (!previousKeys[updatedKey]) {
            addedKeys.push(updatedKey)
        }
    })

    return {
        addedKeys,
        removedKeys
    }

}

export const arrayOfStringsToNiceString = (arr: string[]) => {
    return arr.join(', ');
}
export const parseQueryString = (params: any) => {
    return qs.stringify(params, { encode: true, arrayFormat: 'bracket', skipNull: true });
}

export const addToQueryString = (addQuery: { name: string, value: string }, currentQueryString: string): string => {
    const q = qs.parse(currentQueryString);
    q[addQuery.name] = addQuery.value;
    return qs.stringify(q);
}

export const getStringsSeparatedBy = (strings: string[], separateBy: string, endWithAnd?: string) => {

    const len = strings?.length;
    let ret = '';
    strings?.map((s, i) => {
        if (endWithAnd) {
            const sep = (i + 2) < len ? separateBy + ' ' : endWithAnd && ' ' + endWithAnd + ' ';
            if (i !== len - 1) {
                s += sep;
            }
        } else {
            const sep = (i + 1) < len && separateBy + ' ';
            if (i !== len - 1) {
                s += sep;
            }
        }
        ret += s;
    })
    return ret;
}

export const isAndroidOS = () => {
    return navigator.userAgent.toLowerCase().includes('android');
}

export const isIPadOSOrIPhoneOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
        return true;
    } else if (/iPhone/i.test(userAgent)) {
        return true;
    }
    return false
}

export const isMobileDevice = () => {
    return isAndroidOS() || isIPadOSOrIPhoneOS();
}
export const getInspectionLabel = (orderNumber?: string, itemName?: string, itemNumber?: string): React.ReactElement | string => {
    let on;
    let ina = undefined;
    let inu = undefined;
    if (!isUndefinedOrNullOrEmptyString(orderNumber)) {
        on = orderNumber;
        if (orderNumber.charAt(orderNumber.length - 1) !== '.') {
            on += '.'
        }
    }
    ina = !isUndefinedOrNullOrEmptyString(itemName) ? ' ' + itemName : '';
    inu = !isUndefinedOrNullOrEmptyString(itemNumber) ? ' - ' + itemNumber : '';
    return on + ina + inu;
}

export const isWeChat = () => /MicroMessenger/i.test(window.navigator.userAgent);
export const encodeURIAllChars = (str: string): string => {
    return encodeURI(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

export const encodeURIComponentAllChars = (str: string): string => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

export const getTranslation = (text: TextWithTranslation, lang: string): string => {
    if (lang == text.language || !text.translations || Object.keys(text.translations).length == 0) {
        return text.text;
    }

    return text.translations?.[lang] || text.translations?.['C'];
}

export const getDisplayUser = (user: { firstname: string, lastname: string, email: string }): string => {
    if (user.firstname && user.lastname) {
        return user.firstname + " " + user.lastname;
    }

    return user.firstname || user.lastname || user.email;
}
export const isHexadecimalColorCode = (str: string): boolean => {
    const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    return hexColorPattern.test(str);
}

export function convertFileResourcesToLightBoxMedias(fileResources: FileResource[]): (SlideImage | SlideVideo)[] {
    if (!fileResources || fileResources.length == 0) {
        return [];
    }

    return fileResources
        .filter(fileResource => fileResource?.type == FileResourceTypeEnum.Image || fileResource?.type == FileResourceTypeEnum.Video)
        .map(fileResource => {
            if (fileResource.type == FileResourceTypeEnum.Image) {
                return {
                    type: "image",
                    src: fileResource.image.image_url
                }
            } else {
                return {
                    type: 'video',
                    autoPlay: true,
                    sources: [
                        {
                            src: fileResource.video.video_resources[0].url,
                            type: 'video/mp4',
                        }
                    ],
                }
            }
        })
}

export function convertFileResourceToSingleLightBoxMedia(fileResource: FileResource): (SlideImage | SlideVideo) {
    if (!fileResource) {
        return undefined
    }

    if (fileResource.type == FileResourceTypeEnum.Image) {
        return {
            type: "image",
            src: fileResource.image.image_url
        }
    } else {
        return {
            type: 'video',
            autoPlay: true,
            sources: [
                {
                    src: fileResource.video.video_resources[0].url,
                    type: 'video/mp4',
                }
            ],
        }
    }
}

export function formatMessageWithFallback(localIntl: IntlShape, intl: IntlShape, translationId: string, parameter?: any): string {
    const translation = localIntl.formatMessage({ id: translationId }, parameter);

    if (translation && translation !== translationId) {
        return translation;
    }

    return intl.formatMessage({ id: translationId }, parameter);
}

type IdentityCollection<T> = { [key: string]: T };
export function convertDictionaryToList<T>(dictionary: IdentityCollection<T>): T[] {
    if (!dictionary) {
        return [];
    }
    const keys = Object.keys(dictionary);
    return keys.map((key) => dictionary[key]);
}

export function convertListToDictionary<T>(
    listOrCollection: T[] | IdentityCollection<T>,
    keyOf: (obj: T) => string
): IdentityCollection<T> {
    if (!listOrCollection) {
        return {};
    }

    if (Array.isArray(listOrCollection)) {
        const collection: IdentityCollection<T> = {};

        listOrCollection.forEach((obj) => {
            collection[keyOf(obj)] = obj;
        });

        return collection;
    } else {
        return listOrCollection;
    }
};


