import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GPSLocation } from '../../../backend_api/models';
import EditContextMenuButton from '../../../base/components/basic/EditLink';
import { convertDMS } from '../../../base/utils';
import { TextItem } from '../../globals/components';
import LocationMap from '../../inspections/components/LocationMap';
import QInput from '../../inspections/components/edit/formelements/QInput';
import ContentSection from '../../pageLayouts/components/ContentSection';
import DetailedProductionUnitInfoLocation from './DetailedProductionUnitInfoLocation';

type Props = {
    allowedToEdit: boolean;
    className?: string;
    name: string;
    number: string;
    location: GPSLocation;
    locationCountry: string;
    handleChange(name: string, value: string | GPSLocation, index?: number): void;
};


const DetailedProductionUnitInfo = (props: Props): React.ReactElement => {
    const { name, number, handleChange, allowedToEdit, locationCountry } = props;
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    const [location, setLocation] = useState<GPSLocation>(props.location);

    useEffect(() => {
        setLocation(props.location);
    }, [props.location])
    return <ContentSection
        content={<>
            {allowedToEdit && <EditContextMenuButton className='flex w-full justify-end' onEdit={setEdit} />}
            {!edit && <div className='space-y-3'>
                <TextItem label={intl.formatMessage({ id: 'production_units.detailed_page.info.name' })} text={props.name || '-'} />
                <TextItem label={intl.formatMessage({ id: 'production_units.detailed_page.info.number' })} text={props.number || '-'} />
                {location && <TextItem label={intl.formatMessage({ id: 'production_units.location.label' })}
                    text={<LocationMap
                        location={props.location} locationCountry={locationCountry}
                        label={location ? convertDMS(location.latitude && location.latitude, location.longitude && location.longitude) : intl.formatMessage({ id: 'production_units.no_location.label' })} />} />}
            </div>}
            {edit && <div className='flex flex-col space-y-2'>
                <div className='flex pb-4'>
                    <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.name' /></div>
                    <div className='w-2/4'>
                        <QInput
                            type='text'
                            name='name'
                            value={name}
                            handleBlur={(e, item) => {
                                handleChange(item.name, item.value)
                            }} />
                    </div>
                </div>
                <div className='flex pb-4'>
                    <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.number' /></div>
                    <div className='w-2/4'>
                        <QInput
                            type='text'
                            name='number'
                            value={number}
                            handleBlur={(e, item) => {
                                handleChange(item.name, item.value)
                            }} />
                    </div>
                </div>
            </div >
            }
            <DetailedProductionUnitInfoLocation location={location} handleChange={handleChange} edit={edit} locationCountry={locationCountry} />
        </>}
    />
}
export default DetailedProductionUnitInfo;
