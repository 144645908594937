import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Group } from '../../../backend_api/models';
import { BasicUser } from '../../../backend_api_2';
import Icon from '../../../base/ui/components/icons/Icon';
import ComponentsSeparatedBy from '../../../base/ui/components/util/ComponentsSeparatedBy';
import { getStringsSeparatedBy, twMerge } from '../../../base/utils';
import { OrganizedProductResponsibilityGroup, OrganizedProductResponsibilitySupplier, OrganizedProductResponsibilityUser, ResponsibleGroup, ResponsibleSupplier, ResponsibleUser, SharedDocumentTemplateResponsibleGroup, SharedDocumentTemplateResponsibleUser } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import GroupMembersPopup from '../../groups/components/GroupMembersPopup';
import SupplierPopup from '../../suppliers/components/SupplierPopup';
import UserPopup from '../../users/components/UserPopup';
import { UserNameTextAndEmail, getUserNameText } from '../../users/components/Widgets';

type Props = {
    className?: string;
    users?: ResponsibleUser[] | OrganizedProductResponsibilityUser[] | SharedDocumentTemplateResponsibleUser[];
    groups?: ResponsibleGroup[] | OrganizedProductResponsibilityGroup[] | SharedDocumentTemplateResponsibleGroup[];
    supplier?: ResponsibleSupplier | OrganizedProductResponsibilitySupplier;
    supplierIsResponsible?: boolean;
    productResponsiblesIsResponsible?: boolean;
    showAll?: boolean;
    cutOff?: number;
    returnAsString?: boolean;
};

type DisplayType = {
    name: string;
    firstname?: string;
    lastname?: string;
    id?: string;
    email?: string;
    type: 'user' | 'supplier' | 'group' | 'productResponsible';
}
const ComplianceDisplayResponsibles = (props: Props): any => {
    const { className, users, groups, supplier, showAll = false, returnAsString = false, supplierIsResponsible, productResponsiblesIsResponsible } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();

    const cutOff = props.cutOff || 2;
    const responsibles: DisplayType[] = [];
    supplier && responsibles.push({ name: supplier.supplier_name, id: supplier.supplier_id, type: 'supplier' });
    groups && groups.map((g) => responsibles.push({ name: g.name, id: g.id || g.group_id, type: 'group' }));
    users && users.map((u) => responsibles.push({ name: getUserNameText(u), firstname: u.firstname, lastname: u.lastname, email: u.email, type: 'user' }));
    supplierIsResponsible && responsibles.push({ name: 'Supplier', type: 'supplier' });
    productResponsiblesIsResponsible && responsibles.push({ name: 'Product responsible', type: 'productResponsible' });
    const hasNoResponsibles = responsibles.length === 0;
    useEffect(() => {
        //
    }, []);
    const trigger = () => {
        if (returnAsString) {
            if (hasNoResponsibles) {
                return <span className='italic text-secondary'><FormattedMessage id='compliance.requirements.specification_not_set'/></span>;
            }
            if (showAll) {
                return getStringsSeparatedBy((responsibles as []).map((r: DisplayType) => r.name), ',', (responsibles.length > 2 && 'and'));
            }
        }
        if (hasNoResponsibles) {
            return <div>-</div>;
        }
        return <ComponentsSeparatedBy components={responsibles.map((r) => {
            switch (r.type) {
                case 'user': {
                    return <UserPopup user={{ ...r, disabled: false, last_activity: null, supplier_user: false } as BasicUser} />
                }
                case 'group': {
                    return <GroupMembersPopup group={{ ...r, is_supplier_group: false } as Group} />
                }
                case 'supplier': {
                    return <SupplierPopup supplier={{ ...r }} />
                }
                default: {
                    return <div>{r.name}</div>
                }
            }
            return <div>{r.name}</div>;
        })} separateBy={','} />

        // const res = [...responsibles as []].splice(0, 3);
    }
    const trigger2 = getStringsSeparatedBy(showAll ?
        (responsibles as []).map((r: DisplayType) => r.name)
        :
        [...responsibles as []].splice(0, 3).map((r: DisplayType) => r.name), ',', (responsibles.length > 2 && 'and')
    );
    const pc = <div className='gap-1 flex flex-col w-80'>
        {[...responsibles].splice(3).map((r) => {
            switch (r.type) {
                case 'user': {
                    return <UserNameTextAndEmail user={r} />
                }
                case 'supplier': {
                    return <div>
                        <div className='flex gap-1'><Icon name='user_supplier' />{r.name}</div>
                        <div className='text-secondary text-sm'><FormattedMessage id='compliance.responsibles.supplier' /></div>
                    </div>
                }
                case 'group': {
                    return <div>
                        <div className='flex gap-1'><Icon name='user_group' />{r.name}</div>
                    </div>
                }
                default: {
                    return <div>{r.name}</div>
                }
            }
        })}
    </div>
    if (returnAsString) {
        return trigger() || <span className='italic text-secondary'><FormattedMessage id='compliance.requirements.specification_not_set'/></span>
    }
    return <div className={twMerge('', className)}>
        {!showAll && trigger()}
        {showAll && trigger()}
    </div>
}
export default ComplianceDisplayResponsibles;
