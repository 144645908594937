import classnames from 'classnames';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dimmer, Dropdown, Form, Input, Loader, TextArea } from 'semantic-ui-react';
import DatePicker from '../../../base/components/basic/DatePicker3';
import Icon from '../../../base/components/basic/Icon';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import Button from '../../../base/ui/components/buttons/Button';
import { isUndefinedOrNullOrEmptyString, twMerge } from '../../../base/utils';
import { DetailedRequirement } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { deleteComplianceRequirementTemplate, getComplianceRequirementTemplate } from '../complianceSlice';
import { getComplianceRequirementSelector, isFetchingSelector } from '../selectors/ComplianceSelectors';
import { createTextWithTranslation, useDocumentTemplateScopeOptions, useExpirationRuleOptions } from './ComplianceCreateRequirementTemplate';
import ComplianceDeleteRequirement from './ComplianceDeleteRequirement';
import ComplianceRequirementDocumentApprovalResponsible from './ComplianceRequirementDocumentApprovalResponsible';
import ComplianceRequirementDocumentUploadResponsible from './ComplianceRequirementDocumentUploadResponsible';
import ComplianceTagsBrowser from './ComplianceTagsBrowser';


type ComplianceRequirementTemplateProps = {
    className?: string;
    id: string;
};

const ComplianceRequirementTemplate = (props: ComplianceRequirementTemplateProps): React.ReactElement => {
    const { id, className } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const isFetching = useSelector(isFetchingSelector);
    const templateData = useSelector(getComplianceRequirementSelector);
    // const userCanEditRequirement = templateData
    const [requirement, setRequirementTemplate] = useState<DetailedRequirement>(null);

    const expirationRuleOptions = useExpirationRuleOptions();
    const documentTemplateScopeOptions = useDocumentTemplateScopeOptions();

    const handleUpdateRequirement = (name: string, value: string) => {
        const requirementDataCopy = Object.assign({}, requirement);
        requirementDataCopy[name] = createTextWithTranslation('C', value);
        setRequirementTemplate(requirementDataCopy);
    }

    const handleUpdateRequirementDate = (value: string) => {
        const requirementDataCopy = Object.assign({}, requirement);
        requirementDataCopy.requirement_start_date = value;
        setRequirementTemplate(requirementDataCopy);
    }

    const deleteRequirement = () => {
        dispatch(deleteComplianceRequirementTemplate(id))
    }

    const getDocument = (index: number) => {
        const documentTemplate = requirement.document_templates[index];

        return <div key={documentTemplate.document_template_id} className='border-b py-4 -mx-4 px-4'>
            <Form.Group className='flex space-x-4 w-full'>
                <Form.Field className='w-72 pb-4 md:pb-0'>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.name' /></label>
                    <EditableContent
                        type='text'
                        inputType='input'
                        name={`document_template_name_${index}`}
                        value={documentTemplate.document_template_name && documentTemplate.document_template_name.text}
                        onBlur={() => null}
                    />
                </Form.Field>
                <Form.Field className='w-96 pb-4 md:pb-0' >
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.description' /></label>
                    <EditableContent
                        type='text'
                        inputType='text-area'
                        name={`document_template_description_${index}`}
                        value={documentTemplate.document_template_description && documentTemplate.document_template_description.text}
                        onBlur={() => null}
                    />
                </Form.Field>
                <Form.Field className='w-72 pb-4 md:pb-0'>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.expiration_rule' /></label>
                    <Dropdown
                        disabled
                        className='w-9/12'
                        options={expirationRuleOptions}
                        selection
                        value={documentTemplate.expiration_rule && documentTemplate.expiration_rule.type}
                        onChange={null} />
                </Form.Field>
                <Form.Field className='w-72 pb-4 md:pb-0'>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.expiration_rule' /></label>
                    <Dropdown
                        disabled
                        className='w-9/12'
                        options={documentTemplateScopeOptions}
                        selection
                        value={documentTemplate.scope && documentTemplate.scope.type}
                        onChange={null} />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field className='flex flex-col w-72 pr-4'>
                    <label><FormattedMessage id='compliance.requirements.create_requirement.approve_responsible' /></label>
                    <ComplianceRequirementDocumentApprovalResponsible
                        requirementId={id}
                        documentTemplateId={documentTemplate.document_template_id}
                        responsibleData={documentTemplate.approve_responsible as any} />
                </Form.Field>
                <Form.Field className='flex flex-col'>
                    <label><FormattedMessage id='compliance.requirements.create_requirement.upload_responsible' /></label>
                    <ComplianceRequirementDocumentUploadResponsible
                        requirementId={id}
                        documentTemplateId={documentTemplate.document_template_id}
                        uploadResponsibleData={documentTemplate.upload_responsible} />
                </Form.Field>
            </Form.Group>
            {/* <Button className='px-4 py-1 text-sm' onClick={() => deleteDocumentItem(index)}>Remove</Button> */}
        </div>
    }

    useEffect(() => {
        dispatch(getComplianceRequirementTemplate(id))
    }, []);

    useEffect(() => {
        setRequirementTemplate(templateData);
    }, [templateData]);

    if (requirement) {
        const title = requirement.requirement_name.text || 'Requirement';
        return <PageContainer>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.requirement' }, { name: title })} />

            <Dimmer active={isFetching} inverted>
                <Loader className='dimLoader'><FormattedMessage id='inspections.loading_data_please_wait' /></Loader>
            </Dimmer>
            <ContentSection
                content={<Fragment>
                    <div className={classnames(className, 'pb-6')}>
                        <h3>{title}</h3>
                        <Form>
                            <Form.Field>
                                <label><FormattedMessage id='compliance.requirements_list.create_requirement.description' /></label>
                                <EditableContent
                                    name="requirement_description"
                                    type="text"
                                    inputType="text-area"
                                    value={requirement.requirement_description && requirement.requirement_description.text}
                                    onBlur={(value) => handleUpdateRequirement('requirement_description', value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><FormattedMessage id='compliance.requirements_list.create_requirement.tags' /></label>
                                <ComplianceTagsBrowser
                                    tags={requirement.requirement_tags}
                                    editable={false}
                                    onlyShowLast={true}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><FormattedMessage id='compliance.requirements_list.create_requirement.start_date' /></label>
                                <div className='md:w-10/24'>
                                    <DatePicker
                                        pastDates={true}
                                        date={(!isUndefinedOrNullOrEmptyString(requirement.requirement_start_date) && new Date(requirement.requirement_start_date) || null)}
                                        handleChange={(d) => {
                                            if (d) {
                                                handleUpdateRequirementDate(format(d, DEFAULT_DATE_FORMAT2));
                                            }
                                        }}
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field width={16}>
                                <h3 className='pt-4 pb-0 mb-0'><FormattedMessage id='compliance.requirements_list.create_requirement.document' /></h3>

                                {requirement.document_templates.map((item, i) => {
                                    return getDocument(i)
                                })}
                            </Form.Field>
                            <div className='flex flex-row'>
                                <ComplianceDeleteRequirement
                                    showDeleteModal={true}
                                    handleDelete={deleteRequirement}
                                />
                            </div>
                        </Form>
                    </div>
                </Fragment>}
            />
            <div className='space-y-8'>
                <Button className='' primary><Link className='text-white hover:text-white noUnderline items-center flex' to={'/compliance/requirements'}><FormattedMessage id='globals.confirmation.save' /></Link></Button>
                <Link className='link noUnderline items-center flex' to={'/compliance/requirements'}><Icon name='chevron_left' className='text-xl' /><FormattedMessage id='compliance.requirement.back' /></Link>
            </div>
        </PageContainer>
    }
    return null;
}
export default ComplianceRequirementTemplate;


export function EditableContent(props: {
    isDisabled?: boolean;
    value: string,
    name: string,
    inputType: 'input' | 'text-area',
    type?: string,
    onBlur: (value: string) => void
}) {
    const [editing, setEditing] = React.useState(false);
    const [value, setValue] = React.useState(props.value);

    function handleBlur() {
        props.onBlur(value);
        setEditing(false);
    }

    function handleClick() {
        setEditing(true);
    }

    function handleChange(v: string) {
        setValue(v);
    }

    if (!props.isDisabled && editing) {
        if (props.inputType == 'input') {
            return <Input
                autoFocus={true}
                type={props.type}
                name={props.name}
                value={value}
                onChange={(_, i) => {
                    handleChange(i.value as string);
                }}
                onBlur={handleBlur} />
        } else if (props.inputType == 'text-area') {
            return <TextArea
                autoFocus={true}
                type={props.type}
                name={props.name}
                value={value}
                onChange={(_, i) => {
                    handleChange(i.value as string);
                }}
                onBlur={handleBlur} />
        }
    }
    return <div className={twMerge(!props.isDisabled && 'cursor-pointer hover:bg-gray-300', 'px-0 sm:px-1', value ? 'py-2' : 'py-4')} onClick={handleClick}>{value}</div>
}